import parse from 'html-react-parser';
import './RichTextContent.scss';
import { TextInput as AosTextInput } from '@aos/styleguide-react';
import { useTranslationText } from '@/translation/TranslationHooks';

interface RichTextContentProps {
  value: string;
  dataRole?: string;
  readOnly?: boolean;
}

export default function RichTextContent({ value, dataRole, readOnly }: RichTextContentProps) {
  const { t } = useTranslationText('tickets');
  const placeholder = readOnly ? 'Keine Beschreibung vorhanden' : t('descriptionPlaceholder');

  return (
    <>
      {value ? (
        <div className="aos-textarea" data-role={dataRole}>
          {parse(value ?? '')}
        </div>
      ) : (
        <AosTextInput placeholder={placeholder} readOnly={readOnly} />
      )}
    </>
  );
}
