import { useMutation, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';
import axios, { AxiosProgressEvent, AxiosResponse } from 'axios';
import { useAuth } from 'react-oidc-context';

import queryString from 'query-string';
import useCustomFetch from './CustomFetch';
import { TicketingApiPrefix } from './TicketingApi';
import { MonitoringApiPrefix } from './MonitoringApi';
import useApiErrorHandlers from '../custom-hooks/useApiErrorHandlers';
import { ContractManagementApiPrefix } from './ContractManagementApi';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';
import { FetchError } from './FetchError';

export class MutationPath {
  static readonly CreateTicket = new MutationPath(`${TicketingApiPrefix}/ticket`);

  static readonly CreateArticle = new MutationPath('/api/knowledgeBase/article');

  static readonly CreateTag = new MutationPath('/api/knowledgeBase/tag');

  static readonly UpdateArticle = new MutationPath('/api/knowledgeBase/article');

  static readonly UpdateRule = new MutationPath('/api/automation/rule');

  static readonly RenderMailTemplateServerside = new MutationPath('/api/automation/mail-template');

  static readonly UploadArticleMedia = new MutationPath('/api/knowledgeBase/articleMedia');

  static readonly UploadContractMedia = new MutationPath(`${ContractManagementApiPrefix}/contractMedia`);

  static readonly RunAllDiscoveries = new MutationPath(`${MonitoringApiPrefix}/discovery/all`);

  static readonly RunComponentTypeDiscovery = new MutationPath(`${MonitoringApiPrefix}/discovery`);

  static readonly CreateContract = new MutationPath(`${ContractManagementApiPrefix}/contract`);

  static readonly CreateContractor = new MutationPath(`${ContractManagementApiPrefix}/contractor`);

  static readonly CreateRule = new MutationPath('/api/automation/rule');

  static readonly CreateAdvancedRule = new MutationPath('/api/automation/advanced-rule');

  static readonly CreateComponent = new MutationPath(`${MonitoringApiPrefix}/component`);

  static readonly CreateComponentType = new MutationPath(`${MonitoringApiPrefix}/componentType`);

  static readonly ImportComponentType = new MutationPath(`${MonitoringApiPrefix}/componentType/import`);

  static readonly SaveUserColumnConfig = new MutationPath(`${MonitoringApiPrefix}/user-config/overview`);

  static readonly CreateSystem = new MutationPath(`${MonitoringApiPrefix}/system`);

  static readonly CreateMaintenanceRequest = new MutationPath(`${MonitoringApiPrefix}/maintenance`);

  private readonly path: string;

  private constructor(path: string) {
    this.path = path;
  }

  public static CreateComponentRelation(componentId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/component/${componentId}/relation`);
  }

  public static CreateTicketRelation(ticketId: string): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/ticket/${ticketId}/relation`);
  }

  public static UploadTicketMedia(ticketId?: string): MutationPath {
    return ticketId
      ? new MutationPath(`${TicketingApiPrefix}/ticketMedia?ticketId=${ticketId}`)
      : new MutationPath(`${TicketingApiPrefix}/ticketMedia`);
  }

  public static PutArticle(articleId: string): MutationPath {
    return new MutationPath(`/api/knowledgeBase/article/${articleId}`);
  }

  public static CreateArticleComment(articleId: string): MutationPath {
    return new MutationPath(`/api/knowledgeBase/article/${articleId}/comment`);
  }

  public static PutRule(ruleId: string): MutationPath {
    return new MutationPath(`/api/automation/rule/${ruleId}`);
  }

  public static PutAdvancedRule(advancedRuleId: string): MutationPath {
    return new MutationPath(`/api/automation/advanced-rule/${advancedRuleId}`);
  }

  public static PatchTicket(ticketId: string): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/ticket/${ticketId}`);
  }

  public static PutTicket(ticketId: string): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/ticket/${ticketId}`);
  }

  public static PutOrDeleteFavoriteTickets(tickedId: string): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/ticket/${tickedId}/favorite`);
  }

  public static CreateTicketComment(ticketId: number): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/ticket/${ticketId}/comment`);
  }

  public static CreateTask(ticketId: number): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/ticket/${ticketId}/task`);
  }

  public static DeleteArticle(articleId: string): MutationPath {
    return new MutationPath(`/api/knowledgeBase/article/${articleId}`);
  }

  public static DeleteArticleMedia(mediaId: number): MutationPath {
    return new MutationPath(`/api/knowledgeBase/articleMedia/${mediaId}`);
  }

  public static DeleteTicketMedia(mediaId: number): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/ticketMedia/${mediaId}`);
  }

  public static PatchTask(taskId: number): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/task/${taskId}`);
  }

  public static DeleteTask(taskId: string): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/task/${taskId}`);
  }

  static CreateTaskComment(taskId: number) {
    return new MutationPath(`${TicketingApiPrefix}/task/${taskId}/comment`);
  }

  public static DeleteContractMedia(mediaId: number): MutationPath {
    return new MutationPath(`${ContractManagementApiPrefix}/contractMedia/${mediaId}`);
  }

  public static PatchComponent(componentId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/component/${componentId}`);
  }

  public static PutComponentType(componentTypeId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/componentType/${componentTypeId}`);
  }

  public static DeleteComponentType(componentTypeId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/componentType/${componentTypeId}`);
  }

  public static DeleteContract(contractId: string): MutationPath {
    return new MutationPath(`${ContractManagementApiPrefix}/contract/${contractId}`);
  }

  static PatchContract(contractId: string): MutationPath {
    return new MutationPath(`${ContractManagementApiPrefix}/contract/${contractId}`);
  }

  public static DeleteContractor(contractorId: string): MutationPath {
    return new MutationPath(`${ContractManagementApiPrefix}/contractor/${contractorId}`);
  }

  public static PatchContractor(contractorId: string): MutationPath {
    return new MutationPath(`${ContractManagementApiPrefix}/contractor/${contractorId}`);
  }

  static PutAgent(agentId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/agent/${agentId}`);
  }

  static DeleteAgent(agentId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/agent/${agentId}`);
  }

  static DeleteUserColumnConfig(): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/user-config/overview/reset`);
  }

  static PutSystem(systemId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/system/${systemId}`);
  }

  static DeleteSystem(systemId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/system/${systemId}`);
  }

  static PatchMaintenance(maintenanceRequestId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/maintenance/${maintenanceRequestId}`);
  }

  static PutOrDeleteFavoriteTask(taskId: string): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/task/${taskId}/favorite`);
  }

  public static PutOrDeleteFavoriteComponent(componentId: string): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/component/${componentId}/favorite`);
  }

  static PutTicketFilter(): MutationPath {
    return new MutationPath(`${TicketingApiPrefix}/filter`);
  }

  static PutComponentFilter(): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/component-filter`);
  }

  static PutActiveAlertFilter(): MutationPath {
    return new MutationPath(`${MonitoringApiPrefix}/active-alert-filter`);
  }

  public toString() {
    return this.path;
  }
}

export enum MutationKey {
  PostTicket = 'postticket',
  PatchTicket = 'patchTicket',
  PutTicket = 'putTicket',
  DeleteTicket = 'deleteticket',
  PostTicketComment = 'postticketcomment',
  PostDiscovery = 'postdiscovery',
  PostComponentTypeDiscovery = 'postcomponenttypediscovery',
  PostArticle = 'postarticle',
  PostArticleComment = 'postarticlecomment',
  PostUploadArticleMedia = 'postarticleupload',
  PostUploadTicketMedia = 'postticketupload',
  PostContractMedia = 'postcontractmedia',
  PutArticle = 'putarticle',
  DeleteArticle = 'deleteArticle',
  PostComponentRelation = 'postcomponentrelation',
  DeleteComponentRelation = 'deletecomponentrelation',
  PatchComponent = 'patchcomponent',
  PostComponent = 'postcomponent',
  DeleteComponent = 'deletecomponent',
  PostComponentType = 'postcomponenttype',
  ImportComponentType = 'importcomponenttype',
  PutComponentType = 'putcomponenttype',
  DeleteComponentType = 'deletecomponenttype',
  PostSystem = 'postsystem',
  PutSystem = 'putsystem',
  DeleteSystem = 'deletesystem',
  PostTag = 'posttag',
  PostContract = 'PostContract',
  PostContractor = 'PostContractor',
  PostRule = 'PostRule',
  PutRule = 'PutRule',
  DeleteRule = 'DeleteRule',
  PostAdvancedRule = 'postadvancedrule',
  PutAdvancedRule = 'putadvancedrule',
  DeleteContract = 'DeleteContract',
  PatchContract = 'PatchContract',
  PostTask = 'PostTask',
  PatchTask = 'PatchTask',
  DeleteContractor = 'DeleteContractor',
  PatchContractor = 'PatchContractor',
  PutAgent = 'PutAgent',
  DeleteAgent = 'DeleteAgent',
  PutUserSetting = 'PutUserSetting',
  DeleteTask = 'DeleteTask',
  PutFavoriteTickets = 'PutFavoriteTickets',
  PostTaskComment = 'PostTaskComment',
  DeleteFavoriteTickets = 'DeleteFavoriteTickets',
  UserColumnConfig = 'UserColumnConfig',
  PostMaintenanceRequest = 'PostMaintenaceRequest',
  PatchMaintenanceRequest = 'PatchMaintenanceRequest',
  DeleteTicketRelation = 'deleteticketrelation',
  PostTicketRelation = 'postticketrelation',
  PutFavoriteTask = 'PutFavoriteTask',
  DeleteFavoriteTask = 'DeleteFavoriteTask',
  PutFavoriteComponent = 'putfavoritecomponent',
  DeleteFavoriteComponent = 'deletefavoritecomponent',
  CreateOrUpdateTicketFilter = 'createorupdateticketfilter',
  DeleteTicketFilter = 'deleteticketfilter',
  CreateOrUpdateComponentFilter = 'createorupdatecomponentfilter',
  DeleteComponentFilter = 'deletecomponentfilter',
  CreateOrUpdateActiveAlertFilter = 'createcrupdateactivealertfilter',
  DeleteActiveAlertFilter = 'deleteactivealertfilter',
  MailTemplateServersideRendering = 'MailTemplateServersideRendering',
}

interface DeleteVariables {
  path: string | MutationPath;
}

export type SubmitMethod = 'PATCH' | 'POST';

export type FetchMutationVariables<RequestBodyType> = {
  body: RequestBodyType;
  path: MutationPath;
  requestMethod: SubmitMethod;
};

type PatchMutationOptions<RequestBodyType, ResponseBodyType> = Omit<
  UseMutationOptions<ResponseBodyType, FetchError, PatchMutationVariables<RequestBodyType>>,
  'mutationKey'
>;

type PostMutationOptions<RequestBodyType, ResponseBodyType> = Omit<
  UseMutationOptions<ResponseBodyType, FetchError, PostMutationVariables<RequestBodyType>>,
  'mutationKey'
>;
type PutMutationOptions<RequestBodyType, ResponseBodyType> = Omit<
  UseMutationOptions<ResponseBodyType, FetchError, PutMutationVariables<RequestBodyType>>,
  'mutationKey'
>;
type DeleteMutationOptions = Omit<UseMutationOptions<unknown, FetchError, DeleteVariables>, 'mutationKey'>;

export type PatchMutationVariables<RequestBodyType> = Omit<FetchMutationVariables<RequestBodyType>, 'requestMethod'>;
export type PostMutationVariables<RequestBodyType> = Omit<FetchMutationVariables<RequestBodyType>, 'requestMethod'>;
export type PutMutationVariables<RequestBodyType> = Omit<FetchMutationVariables<RequestBodyType>, 'requestMethod'>;

export function usePatchMutation<RequestBodyType, ResponseBodyType>(
  mutationKey: MutationKey,
  options?: PatchMutationOptions<RequestBodyType, ResponseBodyType>,
): UseMutationResult<ResponseBodyType, FetchError, PatchMutationVariables<RequestBodyType>> {
  const customFetch = useCustomFetch();

  const { handleUnauthorizedError, showErrorToasty } = useApiErrorHandlers();

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: async ({ body, path }: PatchMutationVariables<RequestBodyType>) => {
      const response = await customFetch(path.toString(), {
        method: 'PATCH',
        body: JSON.stringify(body),
      });
      return response as ResponseBodyType;
    },
    ...options,
    onError: async (error, variables, context) => {
      handleUnauthorizedError(error);
      if (options?.onError) {
        await options.onError(error, variables, context);
      } else {
        showErrorToasty(error);
      }
    },
  });
}

export function usePutMutation<RequestBodyType, ResponseBodyType>(
  mutationKey: MutationKey,
  options?: PutMutationOptions<RequestBodyType, ResponseBodyType>,
): UseMutationResult<ResponseBodyType, FetchError, PutMutationVariables<RequestBodyType>> {
  const customFetch = useCustomFetch();

  const { handleUnauthorizedError, showErrorToasty } = useApiErrorHandlers();

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: async ({ body, path }: PutMutationVariables<RequestBodyType>) => {
      const response = await customFetch(path.toString(), {
        method: 'PUT',
        body: body ? JSON.stringify(body) : undefined,
      });
      return response as ResponseBodyType;
    },
    ...options,
    onError: async (error, variables, context) => {
      handleUnauthorizedError(error);
      if (options?.onError) {
        await options.onError(error, variables, context);
      } else {
        showErrorToasty(error);
      }
    },
  });
}

export function usePostMutation<RequestBodyType, ResponseBodyType>(
  mutationKey: MutationKey,
  options?: PostMutationOptions<RequestBodyType, ResponseBodyType>,
): UseMutationResult<ResponseBodyType, FetchError, PostMutationVariables<RequestBodyType>> {
  const customFetch = useCustomFetch();

  const { currentTenant } = useCurrentTenant();
  const selectedTenants = { selectedTenants: [currentTenant] };

  const { handleUnauthorizedError, showErrorToasty } = useApiErrorHandlers();

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: async ({ body, path }: PostMutationVariables<RequestBodyType>) => {
      const pathWithSelectedTenants = path.toString().includes('?')
        ? `${path}&${queryString.stringify(selectedTenants)}`
        : `${path}?${queryString.stringify(selectedTenants)}`;
      const response = await customFetch(pathWithSelectedTenants, {
        method: 'POST',
        body: body ? JSON.stringify(body) : undefined,
      });
      return response as ResponseBodyType;
    },
    ...options,
    onError: async (error, variables, context) => {
      handleUnauthorizedError(error);
      if (options?.onError) {
        await options.onError(error, variables, context);
      } else {
        showErrorToasty(error);
      }
    },
  });
}

export function useUploadMutation<RequestBodyType, ResponseBodyType>(
  mutationKey: MutationKey,
  options?: PostMutationOptions<RequestBodyType, ResponseBodyType>,
  onUploadProgress?: (e?: AxiosProgressEvent) => void,
): UseMutationResult<ResponseBodyType, FetchError, PostMutationVariables<RequestBodyType>> {
  const { user } = useAuth();
  const { handleUnauthorizedError, showErrorToasty } = useApiErrorHandlers();
  const { currentTenant } = useCurrentTenant();

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: async ({ body, path }: PostMutationVariables<RequestBodyType>) => {
      const response = await axios.post<RequestBodyType, AxiosResponse<ResponseBodyType>>(path.toString(), body, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
          Tenant: currentTenant,
        },
        onUploadProgress: (e: AxiosProgressEvent) =>
          onUploadProgress && onUploadProgress(e.total !== e.loaded ? e : undefined),
      });
      return response.data;
    },
    ...options,
    onError: async (error, variables, context) => {
      handleUnauthorizedError(error);
      if (options?.onError) {
        await options.onError(error, variables, context);
      } else {
        showErrorToasty(error);
      }
    },
  });
}

export function useDeleteMutation(
  mutationKey?: MutationKey,
  options?: DeleteMutationOptions,
): UseMutationResult<unknown, FetchError, DeleteVariables> {
  const customFetch = useCustomFetch();
  const { handleUnauthorizedError, showErrorToasty } = useApiErrorHandlers();

  return useMutation({
    mutationKey: [mutationKey],
    mutationFn: async ({ path }: DeleteVariables) =>
      customFetch(path.toString(), {
        method: 'DELETE',
      }),
    ...options,
    onError: async (error, variables, context) => {
      handleUnauthorizedError(error);
      if (options?.onError) {
        await options.onError(error, variables, context);
      } else {
        showErrorToasty(error);
      }
    },
  });
}
