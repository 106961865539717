import { Trans } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { faEllipsisH, faPencil } from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { ArticleMediaUrlBuilders, useGetKnowledgeBaseArticle } from '@/react-query/KnowledgeBaseApi';
import LoadingSpinner from '../../../ui/loading-spinner/LoadingSpinner';
import { useTranslationText } from '@/translation/TranslationHooks';
import ActionBar from '../../../ui/action-bar/ActionBar';
import { MutationKey, MutationPath, useDeleteMutation } from '@/react-query/MutationQueries';
import { invalidateAllArticles } from '@/react-query/InvalidationQueries';
import UserRoleCheck, { useAuthUser } from '../../../auth/UserRoleCheck';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import Modal from '../../../ui/modal/Modal';
import useToggle from '../../../custom-hooks/useToggle';
import { Dropdown } from '@/ui/dropdown/Dropdown';
import Media from '../../../ui/media/Media';
import ArticleTagView from '../article-tag/ArticleTagView';
import { ArticleComponentTypeView } from '../article-component-type/ArticleComponentTypeView';
import useParamId from '../../../custom-hooks/UseParamId';
import { ArticleComponentView } from '../article-component/ArticleComponentView';
import SimilarArticlesWidget from '../article-widget/SimilarArticlesWidget';
import ArticleEditedCreated from '../article-edited-created/ArticleEditedCreated';
import FavoriteStar from '../../../ui/favorite-star/FavoriteStar';
import PrintButton from '../../../ui/print-button/PrintButton';

import './ArticleDetails.scss';
import ArticleCommentsOverview from '../article-comment/ArticleCommentsOverview';
import { KnowledgeBaseArticle } from '@/models/knowledge-base/KnowledgeBaseModel';
import { StrapiFile } from '@/models/strapi/StrapiFile';

export default function ArticleDetails() {
  const { id: articleId, ErrorPage } = useParamId();
  const { data: article = {} as KnowledgeBaseArticle, isLoading, isError } = useGetKnowledgeBaseArticle(articleId);
  const { t } = useTranslationText('knowledgeBases');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tCommon } = useTranslationText('commons');
  const [isModalVisible, toggleModal] = useToggle(false);
  const { hasPermission } = useAuthUser();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteArticle, {
    onSuccess: async () => {
      await invalidateAllArticles(queryClient);
      navigate(-1);
      toast.success(tSuccess('deleteArticle'));
    },
    onError: () => {
      toast.error(`${tError('articleDeleteError')}`);
    },
  });

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    bodyClass: 'article-print',
    documentTitle: `E21X-BMS-${article?.title ?? 'Unbekannter Artikel'}`,
  });

  const onDelete = () => deleteMutate({ path: MutationPath.DeleteArticle(articleId) });

  const getItems = () => {
    const renderItems: JSX.Element[] = [];

    if (hasPermission(UserResourcePermissions.Article.Delete)) {
      renderItems.push(
        <button
          className="dropdown-item delete-article-button"
          type="button"
          data-role="delete-article-button"
          key="delete-article-button"
          onClick={() => toggleModal()}
        >
          {t('deleteArticle')}
        </button>,
      );
    }

    return renderItems;
  };

  return (
    <LoadingSpinner
      isLoading={isLoading}
      errors={[
        isError ? <Trans i18nKey="errorTexts.ArticleError.text" values={{ id: articleId }} /> : undefined,
        ErrorPage,
      ]}
    >
      <div className="flex-container">
        <ActionBar
          right={
            <>
              <PrintButton onClick={() => handlePrint()} data-role="print-article-button" />
              <UserRoleCheck requiredPermission={UserResourcePermissions.Article.Update}>
                <Link to="edit">
                  <button
                    className="button button-actionbar is-primary text-icon-button"
                    type="button"
                    aria-label="edit-button"
                    data-role="edit-article-button"
                  >
                    <span>
                      {t('edit')}
                      <FontAwesomeIcon icon={faPencil} />
                    </span>
                  </button>
                </Link>
              </UserRoleCheck>
              <Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />
            </>
          }
        />
        <div ref={printRef} className="article-details" data-role="article-details">
          <div className="article-details--content">
            <div className="columns is-8">
              <div className="column is-8 main-content border-right">
                <div className="header--article-details">
                  <div>
                    <h1 className="is-font-super-large" data-role="article-title">
                      {article?.title}
                    </h1>
                    {article?.highlighted && (
                      <div className="highlight-article">
                        <FavoriteStar tooltipText={t('highlightedArticles')} />{' '}
                      </div>
                    )}
                  </div>
                  <ArticleTagView tags={article?.tags ?? []} />
                </div>
                <p data-role="article-descriptionShort">
                  <b>{article?.descriptionShort}</b>
                </p>
                <p className="article-content border-bottom" data-role="article-content">
                  {parse(article?.content ?? '')}
                </p>
                <b>{t('comments')}</b>
                <ArticleCommentsOverview articleId={articleId} comments={article?.comments} />
              </div>
              <div className="column is-4 article-widgets">
                <ArticleComponentView components={article?.components || []} />
                <ArticleComponentTypeView componentTypes={article?.componentTypes || []} />
                <Media
                  label={t('attachments')}
                  media={(article?.media as unknown as StrapiFile[]) || []}
                  urlBuilders={ArticleMediaUrlBuilders}
                  isEditable={false}
                  isSideView
                />
                <SimilarArticlesWidget
                  collapsibleTitle={t('similarArticles')}
                  articleId={articleId}
                  widgetKey="similar-articles"
                  hideWidgetIfEmpty
                />
                <ArticleEditedCreated
                  author={article?.author}
                  currentEditor={article?.currentEditor}
                  createdAt={article?.createdAt}
                  updatedAt={article?.updatedAt}
                />
              </div>
            </div>
            <Modal
              isVisible={isModalVisible}
              title={tCommon('deleteArticle')}
              confirmBtnText={tCommon('delete')}
              cancelBtnText={tCommon('back')}
              onCancel={() => toggleModal()}
              onClose={() => toggleModal()}
              onConfirm={onDelete}
            >
              <p>{tCommon('warningArticleDelete')}</p>
            </Modal>
          </div>
        </div>
      </div>
    </LoadingSpinner>
  );
}
