import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import useErrorsCollector from '../../custom-hooks/UseErrorsCollector';
import useParamId from '../../custom-hooks/UseParamId';
import { Agent } from '@/models/monitoring/Agent';
import { useGetAgent } from '@/react-query/AgentApi';
import { MutationKey, MutationPath, usePutMutation } from '@/react-query/MutationQueries';
import { useTranslationText } from '@/translation/TranslationHooks';
import useYupLocal from '../../translation/YupLocal';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import agentValidationSchema from '../AgentValidationSchema';
import { AgentDetailsActionBar } from './agent-details-action-bar/AgentDetailsActionBar';
import { AgentDetailsForm } from './agent-details-form/AgentDetailsForm';
import { AgentModules } from './agent-modules/AgentModules';

export default function AgentDetails() {
  const { id, ErrorPage } = useParamId();
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tCommon } = useTranslationText('commons');

  const navigate = useNavigate();

  const { yup } = useYupLocal();
  const [preventNavigation, setPreventNavigation] = useState(true);
  const formDataRef = useRef<HTMLFormElement>(null);
  const agentFormReturn = useForm<Agent>({
    mode: 'onChange',
    resolver: yupResolver(agentValidationSchema(yup)),
  });

  const { handleSubmit, formState, reset } = agentFormReturn;
  const {
    data: agent,
    isLoading: isAgentLoading,
    error,
  } = useGetAgent(id, {
    gcTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (agent) {
      reset(agent);
    }
  }, [agent, reset]);

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const errors = useErrorsCollector([
    {
      fetchError: error,
      errorText: <Trans i18nKey="errorTexts.404_agent.text" values={{ id }} />,
    },
  ]);

  const { mutate, isPending: isSubmitting } = usePutMutation<Agent, Agent>(MutationKey.PutAgent, {
    onSuccess: () => {
      navigate(-1);
      toast.success(tSuccess('updateAgent'));
    },
    onError: () => {
      toast.error(tError('agentPutError'));
    },
  });

  const isLoading = isAgentLoading || isSubmitting;

  const onSubmit = (updateAgent: Agent) => {
    setPreventNavigation(false);
    mutate({
      body: {
        id: updateAgent.id,
        hostname: updateAgent.hostname,
        lastSeen: updateAgent.lastSeen,
        plattform: updateAgent.plattform,
        architecture: updateAgent.architecture,
        accepted: updateAgent.accepted,
        useDefaultMonitoring: updateAgent.useDefaultMonitoring,
        modules: updateAgent.modules,
      },
      path: MutationPath.PutAgent(id),
    });
  };

  return (
    <LoadingSpinner isLoading={isLoading} errors={[...errors, ErrorPage]}>
      <form className="flex-container" onSubmit={handleSubmit(onSubmit)} ref={formDataRef}>
        <AgentDetailsActionBar formState={formState} isLoading={isLoading} />
        <div className="no-overflow-content">
          <AgentDetailsForm form={agentFormReturn} />
          <AgentModules form={agentFormReturn} />
        </div>
      </form>
    </LoadingSpinner>
  );
}
