import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Collapsible } from '@aos/styleguide-react';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import UserRoleCheck from '../../auth/UserRoleCheck';
import useParamId from '../../custom-hooks/UseParamId';
import useToggle from '../../custom-hooks/useToggle';
import { BaseMaintenance } from '@/models/maintenance/Maintenance';
import { MaintenanceStatus } from '@/models/maintenance/MaintenanceStatus';
import { useGetMaintenanceRequest } from '@/react-query/MaintenanceApi';
import { MutationKey, MutationPath, usePatchMutation } from '@/react-query/MutationQueries';
import { useTranslationText } from '@/translation/TranslationHooks';
import useYupLocal from '../../translation/YupLocal';
import ActionBar from '../../ui/action-bar/ActionBar';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { ContractorFormCollapsible } from '../contractor/ContractorFormCollapsible';
import DetailsForm from '../details/DetailsForm';
import Information from '../information/Information';
import maintenanceRequestValidationSchema from '../MaintenanceValidationSchema';
import ResponsibleForm from '../responsible/ResponsibleForm';
import DeclineMaintenanceRequest from './decline-maintenance-request/DeclineMaintenanceRequest';
import { EditableComponentFormCollapsible } from '../component/EditableComponentFormCollapsible';
import { MaintenanceLocation } from '@/models/maintenance/MaintenanceLocation';
import { Patch, ReplacePatchOperation, replaceProperty } from '@/models/patch/PatchModel';
import { ParallelMaintenances } from './parallel-maintenances/ParallelMaintenances';

export default function MaintenanceDetails() {
  const { id: maintenanceId, ErrorPage } = useParamId();
  const { t } = useTranslationText('maintenances');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');

  const { yup } = useYupLocal();
  const navigate = useNavigate();

  const [showDeclineDialog, toggleShowDeclineDialog] = useToggle(false);

  const maintenanceRequestFormReturn = useForm<BaseMaintenance>({
    mode: 'onChange',
    resolver: yupResolver(maintenanceRequestValidationSchema(yup, t)),
  });

  const [componentsEditable, setComponentsEditable] = useState(false);

  const { formState, reset, control, setValue, getValues } = maintenanceRequestFormReturn;

  const [startOfAction, setStartOfAction] = useState<Date>();
  const [endOfAction, setEndOfAction] = useState<Date>();

  const {
    data: maintenance,
    isLoading,
    isError,
    refetch,
  } = useGetMaintenanceRequest(maintenanceId, {
    gcTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (maintenance) {
      setComponentsEditable(maintenance.status === MaintenanceStatus.REQUEST);
      reset(maintenance);
      setStartOfAction(maintenance.startOfAction);
      setEndOfAction(maintenance.endOfAction);
    }
  }, [maintenance, reset]);

  const setTimeSlot = (startDate: Date, endDate: Date) => {
    setStartOfAction(startDate);
    setEndOfAction(endDate);
  };

  const { mutate, isPending: isUpdating } = usePatchMutation<Patch, BaseMaintenance>(
    MutationKey.PatchMaintenanceRequest,
    {},
  );

  function getReplaceProperty(newValue: any, property: keyof BaseMaintenance): ReplacePatchOperation<BaseMaintenance> {
    return replaceProperty(property, newValue);
  }

  const onChange = async () => {
    const result = await refetch();
    return result.data;
  };

  function onSubmit(
    replaceProperties: ReplacePatchOperation<BaseMaintenance>[],
    isFormValid: boolean = true,
    saveCompleted?: () => void,
    withRedirect: boolean = true,
  ) {
    if (maintenance !== undefined && isFormValid) {
      mutate(
        { body: replaceProperties, path: MutationPath.PatchMaintenance(maintenanceId) },
        {
          onSuccess: async () => {
            saveCompleted?.();
            if (withRedirect) {
              navigate(-1);
            }
            toast.success(tSuccess('updateMaintenanceRequest'));
          },
          onError: () => {
            toast.error(tError('maintenanceRequestPutError'));
          },
        },
      );
    }
  }

  const handleSubmitComponentLocations = (
    maintenanceLocations: MaintenanceLocation[] | undefined,
    saveCompleted: (savedComponentLocations: MaintenanceLocation[] | undefined) => void,
  ) => {
    if (maintenanceLocations !== undefined) {
      onSubmit(
        [getReplaceProperty(maintenanceLocations, 'maintenanceLocations')],
        true,
        async () => {
          const savedMaintenance = await onChange();
          saveCompleted(savedMaintenance?.maintenanceLocations);
        },
        false,
      );
    }
  };

  return (
    <LoadingSpinner
      isLoading={isLoading}
      errors={[
        isError ? (
          <Trans i18nKey="errorTexts.MaintenanceRequestError.text" values={{ id: maintenanceId }} />
        ) : undefined,
        ErrorPage,
      ]}
    >
      <div className="flex-container">
        <UserRoleCheck requiredPermission={UserResourcePermissions.MaintenanceRequest.Update}>
          <ActionBar
            right={
              <>
                <button
                  type="button"
                  className="button"
                  onClick={() => toggleShowDeclineDialog()}
                  disabled={showDeclineDialog || isLoading}
                >
                  {t('decline')}
                </button>
                <button
                  type="button"
                  className={classNames('button is-primary', { 'is-loading': isLoading })}
                  onClick={() =>
                    onSubmit([
                      getReplaceProperty(MaintenanceStatus.APPROVED, 'status'),
                      getReplaceProperty(startOfAction, 'startOfAction'),
                      getReplaceProperty(endOfAction, 'endOfAction'),
                    ])
                  }
                >
                  {t('approve')}
                </button>
              </>
            }
          />
          <DeclineMaintenanceRequest
            show={showDeclineDialog}
            setShow={toggleShowDeclineDialog}
            onSubmit={(reason: string) => {
              onSubmit([
                getReplaceProperty(MaintenanceStatus.DECLINED, 'status'),
                getReplaceProperty({ declineReason: reason }, 'maintenanceRequestDecline'),
              ]);
            }}
            isLoading={isUpdating}
          />
        </UserRoleCheck>
        <form className="no-overflow-content">
          <div>
            <div className="columns is-align-items-flex-start">
              <div className="column is-7">
                <Collapsible isInitiallyCollapsed={false} header={t('details')}>
                  <DetailsForm form={maintenanceRequestFormReturn} readonly setTimeSlot={setTimeSlot} />
                </Collapsible>

                <ParallelMaintenances from={startOfAction} to={endOfAction} maintenanceId={maintenanceId} />
              </div>
              <div className="maintenance-contacts column is-5">
                <Information form={maintenanceRequestFormReturn} />
                <Collapsible isInitiallyCollapsed={false} header={t('responsible')}>
                  <ResponsibleForm formState={formState} formControl={control} setValue={setValue} readOnly />
                </Collapsible>
                <EditableComponentFormCollapsible
                  currentComponentLocations={getValues('maintenanceLocations')}
                  onSubmit={handleSubmitComponentLocations}
                  readOnly={!componentsEditable}
                />
                <ContractorFormCollapsible form={maintenanceRequestFormReturn} readOnly />
              </div>
            </div>
          </div>
        </form>
      </div>
    </LoadingSpinner>
  );
}
