import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useNavigate } from 'react-router';
import { Contractor, CreateContractorModel } from '@/models/contract-management/ContractorModel';
import { createContractorValidationSchema } from '@/models/contract-management/ContractorValidationSchema';
import { useTranslationText } from '@/translation/TranslationHooks';
import useYupLocal from '../../../translation/YupLocal';
import usePreventNavigation from '../../../custom-hooks/PreventNavigation';
import { MutationKey, MutationPath, usePostMutation } from '@/react-query/MutationQueries';
import NavigationRoutes from '../../../routing/NavigationRoutes';
import ActionBar from '../../../ui/action-bar/ActionBar';
import { CreateContractorForm } from './CreateContractorForm';
import './CreateContractor.scss';

export function CreateContractor() {
  const { t } = useTranslationText('contractManagements');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();
  const navigate = useNavigate();

  const contractorFormReturn = useForm<CreateContractorModel>({
    mode: 'onChange',
    resolver: yupResolver(createContractorValidationSchema(yup, t)),
    defaultValues: {
      companyName: '',
      street: '',
      houseNumber: '',
      postalCode: '',
      city: '',
      phoneNumber: '',
      faxNumber: '',
      email: '',
      assigneeIds: [],
    },
  });
  const { isDirty } = contractorFormReturn.formState;

  const [preventNavigation, setPreventNavigation] = useState(true);
  usePreventNavigation(isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const { mutate: postContractor, isPending: isPostingContractor } = usePostMutation<CreateContractorModel, Contractor>(
    MutationKey.PostContractor,
    {
      onSuccess: () => {
        setPreventNavigation(false);
        toast.success(tSuccess('createContractor'));
        setTimeout(() => {
          navigate(NavigationRoutes.AllContractors, { replace: true });
        });
      },
      onError: () => {
        toast.error(tError('createContractorError'));
      },
    },
  );

  const handleSubmitContractor = (contractor: CreateContractorModel) => {
    postContractor({ body: contractor, path: MutationPath.CreateContractor });
  };

  return (
    <form className="flex-container" onSubmit={contractorFormReturn.handleSubmit(handleSubmitContractor)}>
      <ActionBar
        right={
          <button
            className={classNames('button is-primary', { 'is-loading': isPostingContractor })}
            type="submit"
            aria-label="create-button"
            data-role="create-contractor-submit"
            disabled={!isDirty}
          >
            <span>{t('saveContractor')}</span>
          </button>
        }
      />
      <div className="create-contractor columns no-overflow-content">
        <CreateContractorForm form={contractorFormReturn} />
      </div>
    </form>
  );
}
