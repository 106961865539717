import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faLinux, faWindows } from '@fortawesome/free-brands-svg-icons';
import { faDownload, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Table as AosTable } from '@aos/styleguide-react';
import { useGetAllAgents } from '@/react-query/AgentApi';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import { useTranslationText } from '@/translation/TranslationHooks';
import TableHeader, { TableColumn } from '../ui/table-header/TableHeader';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import ActionBar from '../ui/action-bar/ActionBar';
import { useAuthUser } from '@/auth/UserRoleCheck';
import { MutationKey, MutationPath, useDeleteMutation, usePutMutation } from '@/react-query/MutationQueries';
import { Agent } from '@/models/monitoring/Agent';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import { Dropdown } from '@/ui/dropdown/Dropdown';
import DateFormats from '../models/date-formats/DateFormats';
import { invalidateAllAgents } from '@/react-query/InvalidationQueries';
import NavigationRoutes from '../routing/NavigationRoutes';
import LinkTableCell from '../ui/link-table-cell/LinkTableCell';
import TableHintWrapper, { calculateTableState } from '@/ui/table-hint-wrapper/TableHintWrapper';

export default function Agents() {
  const { isLoading, isError, data: agents, refetch: refetchAllAgents } = useGetAllAgents();
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t } = useTranslationText('agents');
  const { t: tCommon } = useTranslationText('commons');
  const { hasPermission } = useAuthUser();
  const queryClient = useQueryClient();

  const tableColumns: TableColumn[] = [
    { property: 'hostname', translationKey: 'hostname', disableSort: true },
    { property: 'plattform', translationKey: 'plattform', disableSort: true },
    { property: 'architecture', translationKey: 'architecture', disableSort: true },
    { property: 'lastSeen', translationKey: 'lastSeen', disableSort: true },
    { property: 'id', translationKey: 'id', disableSort: true },
    { property: 'accepted', translationKey: 'acceptance', disableSort: true },
    { property: '', translationKey: 'actions', disableSort: true, width: '0' },
  ];

  const { mutate } = usePutMutation<Agent, Agent>(MutationKey.PutAgent, {
    onSuccess: async () => {
      await refetchAllAgents();
      toast.success(tSuccess('agentAcceptance'));
    },
    onError: () => {
      toast.error(tError('agentAcceptance'));
    },
  });

  const { mutate: deleteMutate, isPending: isDeleting } = useDeleteMutation(MutationKey.DeleteAgent, {
    onSuccess: async () => {
      await invalidateAllAgents(queryClient);
      toast.success(tSuccess('agentRemoved'));
    },
    onError: () => {
      toast.error(tError('agentRemoved'));
    },
  });

  return (
    <div className="flex-container">
      <ActionBar
        right={
          <Dropdown
            title={<FontAwesomeIcon icon={faDownload} />}
            renderItems={() => [
              <Link
                key="linux_amd4"
                to={t('linux_amd64_url')}
                className="dropdown-item text-icon-button"
                target="_blank"
              >
                <span>
                  <FontAwesomeIcon icon={faLinux as IconProp} /> {t('linux_amd64_label')}
                </span>
              </Link>,
              <Link
                key="darwin_arm64"
                to={t('darwin_arm64_url')}
                className="dropdown-item text-icon-button"
                target="_blank"
              >
                <span>
                  <FontAwesomeIcon icon={faApple as IconProp} /> {t('darwin_arm64_label')}
                </span>
              </Link>,
              <Link
                key="windows_amd64_stamdalone"
                to={t('windows_x64_standalone_url')}
                className="dropdown-item text-icon-button"
                target="_blank"
              >
                <span>
                  <FontAwesomeIcon icon={faWindows as IconProp} /> {t('windows_x64_standalone_label')}
                </span>
              </Link>,
              <Link
                key="windows_amd64_64"
                to={t('windows_x64_installer_url')}
                className="dropdown-item text-icon-button"
                target="_blank"
              >
                <span>
                  <FontAwesomeIcon icon={faWindows as IconProp} /> {t('windows_x64_installer_label')}
                </span>
              </Link>,
            ]}
          />
        }
      />
      <LoadingSpinner isLoading={isLoading} errors={isError ? tError('404_agents') : undefined}>
        <div className="overflow" style={{ flex: 1 }}>
          <TableHintWrapper
            table={
              <AosTable>
                <TableHeader columns={tableColumns} translationSection="agents" currentSort={undefined} />
                <AosTable.Body>
                  {agents?.map(
                    (agent, index) =>
                      (
                        <AosTable.Row key={agent.id} data-id={agent.id} data-role="agent-item" data-row={index}>
                          <LinkTableCell to={NavigationRoutes.AgentId(agent.id)}>{agent.hostname}</LinkTableCell>
                          <LinkTableCell to={NavigationRoutes.AgentId(agent.id)}>{agent.plattform}</LinkTableCell>
                          <LinkTableCell to={NavigationRoutes.AgentId(agent.id)}>{agent.architecture}</LinkTableCell>
                          <LinkTableCell to={NavigationRoutes.AgentId(agent.id)}>
                            {dayjs(agent.lastSeen).format(DateFormats.FULLDATE_HOURS_MINS)}
                          </LinkTableCell>
                          <LinkTableCell to={NavigationRoutes.AgentId(agent.id)}>{agent.id}</LinkTableCell>
                          <AosTable.DataCell>
                            <SwitchInput
                              id={agent.id}
                              checked={agent.accepted}
                              disabled={!hasPermission(UserResourcePermissions.Agent.Update)}
                              onChange={(accepted) => {
                                mutate({
                                  body: {
                                    ...agent,
                                    accepted,
                                  },
                                  path: MutationPath.PutAgent(agent.id),
                                });
                              }}
                            />
                          </AosTable.DataCell>
                          <AosTable.DataCell>
                            <Dropdown
                              isLoading={isDeleting}
                              title={<FontAwesomeIcon icon={faEllipsisH} />}
                              renderItems={() => [
                                <div
                                  onClick={() =>
                                    deleteMutate({
                                      path: MutationPath.DeleteAgent(agent.id),
                                    })
                                  }
                                  key={agent.id}
                                  className="dropdown-item"
                                >
                                  {tCommon('delete')}
                                </div>,
                              ]}
                            />
                          </AosTable.DataCell>
                        </AosTable.Row>
                      ) ?? <></>,
                  )}
                </AosTable.Body>
              </AosTable>
            }
            tableResultOption={calculateTableState(agents ?? [], isError)}
          />
        </div>
      </LoadingSpinner>
    </div>
  );
}
