import { useState } from 'react';
import '../../generic-components/Modal.scss';
import { Button, Modal, ModalCard } from '@aos/styleguide-react';
import { useTranslationText } from '@/translation/TranslationHooks';
import { Environment, useGetChangelog } from '@/react-query/GetChangelogApi';

export default function DemoModal() {
  const { data } = useGetChangelog();
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslationText('commons');

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible || !(data?.environment === Environment.Demo)) return null;

  return (
    <Modal className="demo-modal" isActive>
      <ModalCard
        title={t('DemoModalTitle')}
        content={
          <>
            <p className="modal-text">{t('DemoModalEinleitungssatz')}</p>
            <ul className="modal-list">
              <li>{t('DemoModalListItem1')}</li>
              <li>{t('DemoModalListItem2')}</li>
              <li>{t('DemoModalListItem3')}</li>
              <li>{t('DemoModalListItem4')}</li>
              <li>{t('DemoModalListItem5')}</li>
              <li>{t('DemoModalListItem6')}</li>
            </ul>
          </>
        }
        footer={
          <Button isConfirm onClick={handleClose}>
            OK
          </Button>
        }
        onClose={handleClose}
      />
    </Modal>
  );
}
