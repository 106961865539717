import { MultiValue } from 'react-select';
import { RuleTrigger } from '@/models/operation/AutomationRuleModel';
import { AllTicketFields, TicketField, translationKey } from '@/models/ticket/TicketField';
import { GenericTriggerConfigurationProps } from './GenericTriggerConfigurationProps';
import { useTranslationText } from '@/translation/TranslationHooks';
import MultiSelectDropdown, {
  ReactSelectOption,
  sortByLabel,
} from '../../../ui/multi-select-dropdown/MultiSelectDropdown';

interface TicketModifiedTriggerConfiguration extends RuleTrigger {
  type: 'ticketModified';
  includedFields: TicketField[];
}

type TicketFieldSelectOption = ReactSelectOption<TicketField>;

export default function TicketModifiedTrigger({
  trigger,
  handleConfigurationUpdate,
  onClear,
}: GenericTriggerConfigurationProps<TicketModifiedTriggerConfiguration>) {
  const { t } = useTranslationText('tickets');

  const toSelectOption = (f: TicketField) => ({ label: t(translationKey(f)), value: f });
  const fromSelectOption = (o: TicketFieldSelectOption) => o.value as TicketField;

  const options: TicketFieldSelectOption[] = AllTicketFields.map(toSelectOption).sort(sortByLabel);

  const handleChange = (selectOptions: MultiValue<TicketFieldSelectOption>): void => {
    if (selectOptions && selectOptions.length > 0) {
      handleConfigurationUpdate({
        type: 'ticketModified',
        includedFields: selectOptions.map(fromSelectOption),
      } as TicketModifiedTriggerConfiguration);
    } else {
      onClear();
    }
  };

  return (
    <MultiSelectDropdown
      onChange={handleChange}
      options={options}
      openMenuOnClick
      isSearchable={false}
      placeholder={t('selectFields')}
      mappedValues={(trigger?.includedFields ?? []).map(toSelectOption)}
    />
  );
}
