import { useTranslationText } from '@/translation/TranslationHooks';
import { GenericActionConfigurationProps } from './GenericActionConfigurationProps';
import { RuleAction, RuleTrigger } from '@/models/operation/AutomationRuleModel';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import './SendEmailMessageAction.scss';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import { useGetMailTemplate } from '@/react-query/AutomationApi';
import { useEffect } from 'react';

export interface SendTemplateMailConfiguration extends RuleAction {
  type: 'sendTemplateMailMessage';
  templateName: string;
}

interface SendEmailMessageActionProps extends GenericActionConfigurationProps<SendTemplateMailConfiguration> {
  selectedTrigger: RuleTrigger;
}

export default function SendTemplateMailMessageAction({ selectedTrigger }: SendEmailMessageActionProps) {
  const { t } = useTranslationText('automations');
  const { data: mailTemplate } = useGetMailTemplate(selectedTrigger?.type!, {
    enabled: !!selectedTrigger.type,
  });

  return (
    <div className="send-email-message">
      <FormFieldWrapper label={t('sendToMeMyselfAndI_label')}>
        <SwitchInput id="sendToMyself" checked disabled />
      </FormFieldWrapper>
      <FormFieldWrapper label={t('sendMailSubject_label')}>
        <div className="color-unset" dangerouslySetInnerHTML={{ __html: mailTemplate?.subject ?? '' }} />
      </FormFieldWrapper>
      <FormFieldWrapper label={t('sendMailBody_label')}>
        <div className="color-unset" dangerouslySetInnerHTML={{ __html: mailTemplate?.body ?? '' }} />
      </FormFieldWrapper>
    </div>
  );
}
