import React from 'react';
import './FormFieldWrapper.scss';
import { Control, Field, Label } from '@aos/styleguide-react';
import classNames from 'classnames';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { FieldError, Merge } from 'react-hook-form';
import { useTranslationText } from '@/translation/TranslationHooks';

interface FormFieldWrapperProps {
  children?: React.ReactNode;
  error?: FieldError | Merge<FieldError, (FieldError | undefined)[]> | undefined;
  label?: string;
  thinLabel?: string;
  size?: BulmaSize;
  controlIconLeft?: string;
  controlIconRight?: string;
  isRequired?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isUpdated?: boolean;
  controls?: ControlElement[];
  helpers?: HelperElement[];
  onlyError?: boolean;
  noGridControl?: boolean;
  controlClassnames?: string;
}

export interface ControlElement {
  component: JSX.Element;
  isExpanded?: boolean;
}

interface HelperElement {
  key: string;
  text?: string;
  isError?: boolean;
}

export default function FormFieldWrapper({
  children,
  error,
  label,
  thinLabel,
  isRequired = false,
  isFullWidth = true,
  size = 'is-normal',
  controlIconLeft,
  controlIconRight,
  isLoading = false,
  isDisabled = false,
  isUpdated = false,
  controls,
  helpers,
  onlyError = false,
  noGridControl = false,
  controlClassnames,
}: FormFieldWrapperProps) {
  const { t } = useTranslationText('errorTexts');
  const errorMessage = error !== undefined && (Array.isArray(error) ? error.map((err) => t(err.type)) : error.message);

  const controlsJSX = controls?.map((element) => (
    <Control
      leftIcon={controlIconLeft}
      rightIcon={controlIconRight}
      key={element.component.key}
      isExpanded={element.isExpanded}
      className={classNames(controlClassnames, {
        'is-fullwidth': isFullWidth,
        'is-loading': isLoading,
        'is-updated--success': isUpdated,
        'no-grid': noGridControl,
      })}
      size={size}
    >
      {element.component}
      {isLoading && <span className="loading-spinner" />}
    </Control>
  ));

  const helpersJSX = (
    <div className="aos-form-helpers">
      {helpers?.map((element) => (
        <div key={`${element.key}`} className={classNames('helper', size, { 'is-error': element.isError })}>
          {element.text}
        </div>
      ))}
      {errorMessage &&
        Array.isArray(errorMessage) &&
        errorMessage.map((element) => (
          <div
            key={`${element}-${element.length}-${size}-${label}-${children?.toString()}`}
            className={classNames('helper', 'is-error', size)}
          >
            {element}
          </div>
        ))}
      {errorMessage && typeof errorMessage === 'string' && (
        <div className={classNames('helper', 'is-error', size)}>{errorMessage}</div>
      )}
    </div>
  );

  const thinLabelJSX = (
    <Label isThin size={size}>
      {thinLabel}
    </Label>
  );

  const childrenJSX = (
    <Control
      leftIcon={controlIconLeft}
      rightIcon={controlIconRight}
      className={classNames(controlClassnames, {
        'is-fullwidth': isFullWidth,
        'is-loading': isLoading,
        'is-updated--success': isUpdated,
        'no-grid': noGridControl,
      })}
      size={size}
    >
      {children}
      {isLoading && <span className="loading-spinner" />}
    </Control>
  );

  if (onlyError) {
    return (
      <>
        {label && (
          <Label size={size} isRequired={isRequired} isHidden={isDisabled}>
            {label}
          </Label>
        )}
        {children}
        {helpersJSX}
      </>
    );
  }

  if (children) {
    return (
      <Field className={classNames({ 'is-fullwidth': isFullWidth })}>
        <Label size={size} isRequired={isRequired} isHidden={label === undefined || isDisabled}>
          {label}
        </Label>
        {thinLabel ? (
          <div className="form-grid">
            {thinLabelJSX}
            <div className="aos-controls">{childrenJSX}</div>
            {helpersJSX}
          </div>
        ) : (
          <>
            {childrenJSX}
            {helpersJSX}
          </>
        )}
      </Field>
    );
  }

  return (
    <Field className={classNames({ 'is-fullwidth': isFullWidth })}>
      <Label size={size} isRequired={isRequired} isHidden={label === undefined || isDisabled}>
        {label}
      </Label>
      {controls && controls.length === 1 && !thinLabel ? (
        <>
          {controlsJSX}
          {helpersJSX}
        </>
      ) : undefined}
      {(controls && controls.length > 1) || thinLabel ? (
        <div className="form-grid">
          {thinLabelJSX}
          <div className="aos-controls">{controlsJSX}</div>
          {helpersJSX}
        </div>
      ) : undefined}
    </Field>
  );
}
