import { ReactNode } from 'react';
import { Hint } from '@aos/styleguide-react';
import { faCircleInfo, faFireFlameCurved } from '@fortawesome/free-solid-svg-icons';
import { useTranslationText } from '@/translation/TranslationHooks';
import { BulmaColor, type HintColor } from '@aos/styleguide-react/dist/common/constants';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { TableResultOption } from '@/models/wrapped-table/WrappedTableTypes';

export type TableHintConfig = {
  color: HintColor;
  icon: IconDefinition;
  titleTextKey: string;
  bodyTextKey: string;
};

export const tableHintConfig = {
  [TableResultOption.NoData]: {
    color: BulmaColor.Info,
    icon: faCircleInfo,
    titleTextKey: 'noEntriesTitle',
    bodyTextKey: 'noEntries',
  } as TableHintConfig,
  [TableResultOption.NoResult]: {
    color: BulmaColor.Info,
    icon: faCircleInfo,
    titleTextKey: 'noMatchingEntriesTitle',
    bodyTextKey: 'noMatchingEntries',
  } as TableHintConfig,
  [TableResultOption.Error]: {
    color: BulmaColor.Danger,
    icon: faFireFlameCurved,
    titleTextKey: 'errorLoading',
    bodyTextKey: 'errorLoadingAdvice',
  } as TableHintConfig,
};

export function calculateTableState(data: unknown[], error: boolean, hasFilter = false): TableResultOption {
  if (error) return TableResultOption.Error;
  if (data.length === 0) return hasFilter ? TableResultOption.NoResult : TableResultOption.NoData;

  return TableResultOption.Success;
}

interface TableHintWrapperProps {
  table: ReactNode;
  tableResultOption: TableResultOption;
  noFlex?: boolean;
}

function TableHintWrapper({ table, tableResultOption, noFlex = false }: TableHintWrapperProps) {
  const { t } = useTranslationText('commons');

  const config = tableHintConfig[tableResultOption];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: `${noFlex ? '' : 'flex'}`,
        flexDirection: 'column',
        gap: '32px',
        alignItems: 'center',
      }}
    >
      {table}
      {tableResultOption !== TableResultOption.Success && (
        <div
          style={{
            width: '440px',
            height: '77px',
            margin: `${noFlex ? '32px auto 0 auto' : ''}`,
          }}
        >
          <Hint
            style={{ width: '100%' }}
            color={config.color}
            icon={config.icon.iconName}
            title={t(config.titleTextKey)}
          >
            <span>{t(config.bodyTextKey)}</span>
          </Hint>
        </div>
      )}
    </div>
  );
}

export default TableHintWrapper;
