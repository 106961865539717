import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Table as AosTable } from '@aos/styleguide-react';
import { useTranslationText } from '@/translation/TranslationHooks';
import LinkTableCell from '../ui/link-table-cell/LinkTableCell';
import LoadingSpinner from '../ui/loading-spinner/LoadingSpinner';
import TableHeader, { TableColumn } from '../ui/table-header/TableHeader';
import NavigationRoutes from '../routing/NavigationRoutes';
import './ComponentTypes.scss';
import ActionBar from '../ui/action-bar/ActionBar';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import { useAuthUser } from '@/auth/UserRoleCheck';
import { useGetComponentTypes } from '@/react-query/ComponentTypeApi';
import { Dropdown } from '@/ui/dropdown/Dropdown';
import NavigateButton from '../generic-components/navigate-button/NavigateButton';
import TableHintWrapper, { calculateTableState } from '@/ui/table-hint-wrapper/TableHintWrapper';

const tableColumns: TableColumn[] = [
  {
    translationKey: 'name',
    property: 'name',
    disableSort: true,
  },
  {
    translationKey: 'description',
    property: 'description',
    disableSort: true,
  },
];

export default function ComponentTypes() {
  const { t } = useTranslationText('componentTypes');
  const { isLoading, data: componentTypes, isError } = useGetComponentTypes({});
  const { hasPermission } = useAuthUser();

  const getItems = () => {
    const renderItems: JSX.Element[] = [];
    if (hasPermission(UserResourcePermissions.ComponentType.Create)) {
      renderItems.push(
        <Link className="dropdown-item" type="button" to="import">
          {t('importComponentType')}
        </Link>,
      );
    }
    return renderItems;
  };

  return (
    <div className="flex-container">
      <ActionBar
        right={
          <>
            <NavigateButton
              roleCheckKey="create-new-component-type-btn"
              requiredPermission={UserResourcePermissions.ComponentType.Create}
              to="create"
              text={t('createNewComponentType')}
              size="is-small"
              icon="add"
              isConfirm
            />
            <Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />
          </>
        }
      />
      <LoadingSpinner isLoading={isLoading}>
        <div className="overflow" style={{ flex: 1 }}>
          <TableHintWrapper
            table={
              <AosTable>
                <TableHeader columns={tableColumns} translationSection="componentTypes" currentSort={undefined} />
                <AosTable.Body>
                  {componentTypes?.content.map((componentType) => {
                    const routeToDetail = NavigationRoutes.ComponentTypeId(componentType.id);
                    return (
                      <AosTable.Row
                        className="is-clickable"
                        key={componentType.id}
                        data-id={componentType.id}
                        data-role="component-type-item"
                      >
                        <LinkTableCell className="component-type-name" to={routeToDetail}>
                          {componentType.name}
                        </LinkTableCell>
                        <LinkTableCell to={routeToDetail}>
                          <div className="component-type-description">{componentType.description}</div>
                        </LinkTableCell>
                      </AosTable.Row>
                    );
                  })}
                </AosTable.Body>
              </AosTable>
            }
            tableResultOption={calculateTableState(componentTypes?.content ?? [], isError)}
          />
        </div>
      </LoadingSpinner>
    </div>
  );
}
