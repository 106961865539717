import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserRoleCheck, { useAuthUser } from '../auth/UserRoleCheck';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import ActionBar from '../ui/action-bar/ActionBar';
import { RuleEnum } from './RuleConstants';
import RuleTriggerOverview from './trigger/RuleTriggerOverview';
import RuleConditionsOverview from './condition/RuleConditionsOverview';
import RuleActionsOverview from './action/RuleActionsOverview';
import { useTranslationText } from '@/translation/TranslationHooks';
import './AutomationRules.scss';
import { AutomationRule, AutomationRuleWithOptionalId, RuleAction } from '@/models/operation/AutomationRuleModel';
import usePreventNavigation from '../custom-hooks/PreventNavigation';
import { Dropdown } from '@/ui/dropdown/Dropdown';
import Modal from '../ui/modal/Modal';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import { UserPermission } from '@/auth/UserPermission';
import FormFieldWrapper from '../ui/form-field-wrapper/FormFieldWrapper';

interface RuleEditorProps {
  data?: AutomationRule;
  permission: UserPermission;
  onSubmit: (ruleDto: AutomationRuleWithOptionalId) => void;
  onDelete?: (ruleId: string) => void;
  isLoading?: boolean;
}

export default function RuleEditor({ data, permission, onSubmit, onDelete, isLoading }: RuleEditorProps) {
  const initRule = {
    name: '',
    description: '',
    active: true,
    actions: [],
    conditions: [],
    trigger: undefined,
  };
  const [oldRule, setOldRule] = useState<AutomationRuleWithOptionalId>(initRule);
  const [newRule, setNewRule] = useState<AutomationRuleWithOptionalId>(initRule);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const layer = {
    first: 3,
    second: 2,
    third: 1,
  };

  const [currentStep, setCurrentStep] = useState(RuleEnum.CREATE_TRIGGER);
  const [invalidSteps, setInvalidSteps] = useState<Set<RuleEnum>>(new Set());
  const [isSubmit, setIsSubmit] = useState(false);
  const { t } = useTranslationText('automations');
  const { t: tCommon } = useTranslationText('commons');
  const { t: tError } = useTranslationText('errorTexts');

  usePreventNavigation(isDirty, tCommon('discardOpenChangesQuestion'));
  const { hasPermission } = useAuthUser();

  useEffect(() => {
    if (oldRule && !isEqual(oldRule, newRule)) {
      setIsDirty(true);
    }
  }, [newRule, oldRule]);

  useEffect(() => {
    if (data) {
      const mappedData = {
        id: data.id,
        actions: [...data.actions],
        conditions: [...data.conditions],
        trigger: data.trigger,
        name: data.name,
        description: data.description,
        active: data.active,
      };
      setNewRule(mappedData);
      setOldRule(mappedData);
      setIsDirty(false);
    }
  }, [data]);

  const validate = (isValid: boolean, step: RuleEnum) => {
    setInvalidSteps((prev) => {
      if (isValid) {
        if (prev.has(step)) {
          const old = new Set([...prev]);
          old.delete(step);
          return old;
        }
        return prev;
      }
      return prev.add(step);
    });
  };

  const setStep = (step: RuleEnum) => {
    setCurrentStep(step);
  };

  const onSave = () => {
    validate(newRule.name.length > 0, RuleEnum.RULE_NAME); // Für die Validierung beim Speichern
    setIsSubmit(true);
    if (invalidSteps.size === 0) {
      onSubmit(newRule);
    }
  };

  const isDeletable = () => data && onDelete;

  const showOnlySummary = (step: RuleEnum) => step !== currentStep;

  const onActionConfigured = useCallback(
    (action: RuleAction) => {
      setNewRule((prevState) => ({
        ...prevState,
        actions: [...(prevState.actions ?? []).filter((a) => a.type !== action.type), action],
      }));
    },
    [setNewRule],
  );

  const getItems = () => {
    const renderItems: JSX.Element[] = [];

    if (isDeletable() && hasPermission(UserResourcePermissions.Rule.Delete)) {
      renderItems.push(
        <a
          className="dropdown-item"
          onClick={() => setIsModalVisible(true)}
          data-role="rule-delete-button"
          key="rule-delete-button"
        >
          {t('ruleDelete')}
        </a>,
      );
    }

    return renderItems;
  };

  return (
    <UserRoleCheck requiredPermission={permission}>
      <div className="flex-container">
        <ActionBar
          right={
            <>
              <div className="rule-active-toggle">
                <FormFieldWrapper label={`${t('rule')} ${newRule.active ? t('active') : t('inactive')}`}>
                  <SwitchInput
                    id="rule-active-toggle"
                    checked={newRule.active}
                    onChange={(isCheck) =>
                      setNewRule((prevState) => ({
                        ...prevState,
                        active: isCheck,
                      }))
                    }
                  />
                </FormFieldWrapper>
              </div>
              <div className="rule-active-toggle">
                <FormFieldWrapper
                  isRequired
                  label={t('ruleNameLabel')}
                  helpers={[
                    {
                      key: `rule-active-toggle-${tError('required')}`,
                      text: isSubmit && !(newRule.name.length > 0) ? tError('required') : '',
                      isError: true,
                    },
                  ]}
                >
                  <input
                    size={newRule.name.length}
                    className={classNames('input', 'is-button-size', 'rule-name-input', {
                      redBorder: isSubmit && invalidSteps.has(RuleEnum.RULE_NAME),
                    })}
                    placeholder={t('ruleNamePlaceholder')}
                    type="text"
                    value={newRule.name}
                    onChange={(e) => {
                      validate(e.target.value.trim().length > 0, RuleEnum.RULE_NAME);
                      setNewRule((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }));
                    }}
                    data-role="rule-name-input"
                  />
                </FormFieldWrapper>
              </div>
              <div className="rule-active-toggle">
                <button
                  type="button"
                  className={classNames('button button-actionbar is-primary', { 'is-loading': isLoading })}
                  data-role="create-rule-submit-button"
                  onClick={onSave}
                >
                  {tCommon('save')}
                </button>
              </div>
              <Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />
            </>
          }
        />
        <div className="rule-cols no-overflow-content" data-role="rule-cols">
          <div
            style={{ zIndex: layer.first }}
            className={classNames('collapsable-col', 'trigger', {
              active: currentStep === RuleEnum.CREATE_TRIGGER,
            })}
            onClick={() => setStep(RuleEnum.CREATE_TRIGGER)}
          >
            <RuleTriggerOverview
              trigger={newRule.trigger}
              showOnlySummary={showOnlySummary(RuleEnum.CREATE_TRIGGER)}
              validate={isSubmit}
              onValidation={(isValid) => validate(isValid, RuleEnum.CREATE_TRIGGER)}
              onTriggerSelect={(trigger) =>
                setNewRule((prevState) => ({
                  ...prevState,
                  trigger,
                }))
              }
              onContinueClicked={() => setCurrentStep(RuleEnum.CREATE_CONDITION)}
              data-role="trigger-column"
            />
          </div>
          <div
            style={{ zIndex: layer.second }}
            className={classNames('collapsable-col', 'condition', {
              active: currentStep === RuleEnum.CREATE_CONDITION,
            })}
            onClick={() => setStep(RuleEnum.CREATE_CONDITION)}
            data-role="condition-column"
          >
            <RuleConditionsOverview
              conditions={newRule.conditions}
              showOnlySummary={showOnlySummary(RuleEnum.CREATE_CONDITION)}
              onConditionConfigured={(condition) =>
                setNewRule((prevState) => ({
                  ...prevState,
                  conditions: [...(prevState.conditions ?? []).filter((c) => c.type !== condition.type), condition],
                }))
              }
              onContinueClicked={() => setCurrentStep(RuleEnum.CREATE_ACTION)}
              onUncheckCondition={(removedCondition) =>
                setNewRule((prevState) => ({
                  ...prevState,
                  conditions: prevState.conditions.filter((c) => c !== removedCondition),
                }))
              }
            />
          </div>
          <div
            style={{ zIndex: layer.third }}
            className={classNames('collapsable-col', 'action', {
              active: currentStep === RuleEnum.CREATE_ACTION,
            })}
            onClick={() => setStep(RuleEnum.CREATE_ACTION)}
            data-role="action-column"
          >
            <RuleActionsOverview
              actions={newRule.actions}
              selectedTrigger={newRule.trigger}
              showOnlySummary={showOnlySummary(RuleEnum.CREATE_ACTION)}
              validate={isSubmit}
              onValidation={(isValid) => validate(isValid, RuleEnum.CREATE_ACTION)}
              onUncheckAction={(removedAction) => {
                setNewRule((prevState) => ({
                  ...prevState,
                  actions: prevState.actions.filter((a) => a !== removedAction),
                }));
              }}
              onActionConfigured={onActionConfigured}
            />
          </div>
        </div>
        {isDeletable() && (
          <Modal
            isVisible={isModalVisible}
            title={t('ruleDelete')}
            cancelBtnText={tCommon('abort')}
            confirmBtnText={tCommon('delete')}
            onCancel={() => setIsModalVisible(false)}
            onConfirm={() => onDelete!(data!.id)}
            onClose={() => setIsModalVisible(false)}
          >
            <p>{t('warningRuleDelete')}</p>
          </Modal>
        )}
      </div>
    </UserRoleCheck>
  );
}
