import classNames from 'classnames';
import React from 'react';
import { useTranslationText } from '@/translation/TranslationHooks';

export interface ModalProps {
  children?: any;
  title: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  isVisible: boolean;
  disabledSaveBtn?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  hideCancelButton?: boolean;
  hideSubmitButton?: boolean;
}

export default function Modal({
  children,
  title,
  isVisible,
  disabledSaveBtn,
  onConfirm,
  onCancel,
  onClose,
  confirmBtnText,
  cancelBtnText,
  hideCancelButton,
  hideSubmitButton,
}: ModalProps) {
  const { t } = useTranslationText('commons');
  const actuallyConfirmBtnText = confirmBtnText || t('save');
  const actuallyAbortBtnText = cancelBtnText || t('abort');

  function handleConfirm(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (onConfirm) onConfirm();
  }

  return isVisible ? (
    <div className={classNames('modal', { 'is-active': isVisible })} data-role="modal">
      <div className="modal-background" onClick={onClose} />
      <div className="modal-card modal-width-m">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button type="button" className="aos-delete" aria-label="close" onClick={onClose} />
        </header>
        <section className="modal-card-body">{children}</section>
        <footer className="modal-card-foot">
          {!hideSubmitButton && (
            <button
              type="submit"
              className="button is-primary"
              data-role="submit-button"
              disabled={disabledSaveBtn}
              onClick={handleConfirm}
            >
              {actuallyConfirmBtnText}
            </button>
          )}
          {!hideCancelButton && (
            <button type="button" className="button" onClick={onCancel}>
              {actuallyAbortBtnText}
            </button>
          )}
        </footer>
      </div>
    </div>
  ) : (
    <></>
  );
}
