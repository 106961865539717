import './Dashboard.scss';
import { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import useRuntimeConfig from '../runtime-config/RuntimeConfig';
import { useAuthUser } from '../auth/UserRoleCheck';
import { UserResourcePermissions } from '../auth/AuthUserRoles';
import { useTranslationText } from '../translation/TranslationHooks';
import IFrameNavigator from './IFrameNavigator';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';
import { appendQuery } from '@/utils/BmsUrl';

function mapUserDataToDisplayName(userData: User | null | undefined): string | undefined {
  const profile = userData?.profile;
  if (profile === null || profile === undefined) {
    return undefined;
  }
  if (profile.given_name && profile.family_name) {
    return `${profile.given_name} ${profile.family_name}`;
  }
  return profile.preferred_username;
}

function useTicketingDashboardName() {
  const { currentTenant } = useCurrentTenant();
  return `Ticketing-Reports-Fallback?var-tenant=${currentTenant}`;
}

function useTicketingDashboardUrl() {
  const [runtimeConfig] = useRuntimeConfig();
  const dashboardName = useTicketingDashboardName();
  const [state] = useState(Math.random);

  return appendQuery(`${runtimeConfig?.dashboardUrl}/${dashboardName}`, `theme=light&kiosk&state=${state}`);
}

export function AlternativeDashboard() {
  const { user } = useAuth();
  const { t } = useTranslationText('commons');
  return (
    <div>
      <h1>
        {t('greetings')} {mapUserDataToDisplayName(user) ?? ''}!
      </h1>
      <p>{t('emptyDashboard')}</p>
    </div>
  );
}

export default function Dashboard() {
  const { hasPermission } = useAuthUser();
  const [runtimeConfig] = useRuntimeConfig();

  const dashboardUrl = useTicketingDashboardUrl();

  return hasPermission(UserResourcePermissions.Ticket.Read) && hasPermission(UserResourcePermissions.Component.Read) ? (
    <>
      {runtimeConfig && (
        <iframe title="dashboard" className="dashboard-frame" src={dashboardUrl} onLoad={IFrameNavigator} />
      )}
    </>
  ) : (
    <AlternativeDashboard />
  );
}
