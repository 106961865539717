import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import useToggle from '../../custom-hooks/useToggle';
import useParamId from '../../custom-hooks/UseParamId';
import { ComponentType, ComponentTypeModel } from '@/models/operation/ComponentTypeModel';
import { useGetComponentType } from '@/react-query/ComponentTypeApi';
import { invalidateAllComponentTypes } from '@/react-query/InvalidationQueries';
import { MutationKey, MutationPath, useDeleteMutation, usePutMutation } from '@/react-query/MutationQueries';
import { useTranslationText } from '@/translation/TranslationHooks';
import useYupLocal from '../../translation/YupLocal';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import Modal from '../../ui/modal/Modal';
import AttributesFormCollapsible from '../attributes-form/AttributesFormCollapsible';
import ComponentSelectorForm from '../component-selector-form/ComponentSelectorForm';
import componentTypeValidationSchema from '../ComponentTypeValidationSchema';
import DetailsForm from '../details-form/DetailsForm';
import DisplayNameSelectorForm from '../display-name-selector-form/DisplayNameSelectorForm';
import RelationTemplates from '../relation-templates/RelationTemplates';
import { EditComponentTypeActionBar } from './EditComponentTypeActionBar';
import { ComponentTypeDetails } from '../component-type-details/ComponentTypeDetails';
import { useAuthUser } from '@/auth/UserRoleCheck';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import './EditComponentType.scss';
import AlertingRulesFormCollapsible from '../alerting-rules-form/AlertingRulesFormCollapsible';

export default function EditComponentType() {
  const { id: componentTypeId, ErrorPage } = useParamId();

  const { t } = useTranslationText('componentTypes');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tCommon } = useTranslationText('commons');
  const [isModalVisible, toggleModal] = useToggle(false);

  const { yup } = useYupLocal();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [preventNavigation, setPreventNavigation] = useState(true);
  const formDataRef = useRef<HTMLFormElement>(null);
  const componentTypeFormReturn = useForm<ComponentTypeModel>({
    mode: 'onChange',
    resolver: yupResolver(componentTypeValidationSchema(yup, t)),
    defaultValues: {
      componentSelector: { labels: [] },
      displayNameSelector: {},
      relationTemplates: [],
    },
  });

  const { handleSubmit, formState, reset } = componentTypeFormReturn;

  const {
    data: componentType,
    isLoading: componentTypeIsLoading,
    isError,
  } = useGetComponentType(componentTypeId, {
    gcTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (componentType) {
      reset(componentType);
    }
  }, [componentType, reset]);

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const { mutate, isPending } = usePutMutation<ComponentTypeModel, ComponentType>(MutationKey.PutComponentType, {
    onSuccess: () => {
      navigate(-1);
      toast.success(tSuccess('updateComponentType'));
    },
    onError: () => {
      toast.error(tError('componentTypePutError'));
    },
  });

  const onSubmit = (updateComponentType: ComponentTypeModel) => {
    setPreventNavigation(false);
    mutate({
      body: {
        name: updateComponentType.name,
        description: updateComponentType.description,
        componentSelector: updateComponentType.componentSelector,
        attributes: updateComponentType.attributes,
        displayNameSelector: updateComponentType.displayNameSelector,
        relationTemplates: updateComponentType.relationTemplates,
        alertingRules: updateComponentType.alertingRules,
        iconUrl: updateComponentType.iconUrl,
      },
      path: MutationPath.PutComponentType(componentTypeId),
    });
  };

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteComponentType, {
    onSuccess: async () => {
      await invalidateAllComponentTypes(queryClient);
      navigate(-1);
      toast.success(tSuccess('deleteComponentType'));
    },
    onError: () => {
      toast.error(`${tError('componentTypeDeleteError')}`);
    },
  });

  const onDelete = () => deleteMutate({ path: MutationPath.DeleteComponentType(componentTypeId) });

  const { hasPermission } = useAuthUser();
  if (!componentType?.isEditable || !hasPermission(UserResourcePermissions.ComponentType.Update)) {
    return <ComponentTypeDetails />;
  }

  return (
    <LoadingSpinner
      isLoading={componentTypeIsLoading}
      errors={[
        isError ? <Trans i18nKey="errorTexts.ComponentTypeError.text" values={{ id: componentTypeId }} /> : undefined,
        ErrorPage,
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)} ref={formDataRef} className="flex-container">
        <EditComponentTypeActionBar
          formState={formState}
          isLoading={isPending}
          componentTypeId={componentTypeId}
          toggleDeleteComponentTypeModal={toggleModal}
        />
        <div className="columns is-align-items-flex-start no-overflow-content">
          <div className="column is-7 edit-component-type-column">
            <DetailsForm form={componentTypeFormReturn} />
            {/* Falls es sich um den logischen KomponentenTyp handelt, müssen die folgenden beiden Komponenten nicht dargestellt werden */}
            {componentType?.isEditable && <ComponentSelectorForm form={componentTypeFormReturn} />}
            {componentType?.isEditable && <DisplayNameSelectorForm form={componentTypeFormReturn} />}
            <RelationTemplates form={componentTypeFormReturn} />
          </div>
          <div className="column is-5 edit-component-type-column">
            <AttributesFormCollapsible form={componentTypeFormReturn} />
            <AlertingRulesFormCollapsible form={componentTypeFormReturn} />
          </div>
        </div>
      </form>
      <Modal
        isVisible={isModalVisible}
        title={tCommon('deleteComponentType')}
        confirmBtnText={tCommon('delete')}
        cancelBtnText={tCommon('back')}
        onCancel={() => toggleModal()}
        onClose={() => toggleModal()}
        onConfirm={onDelete}
      >
        <p>{tCommon('warningComponentTypeDelete')}</p>
      </Modal>
    </LoadingSpinner>
  );
}
