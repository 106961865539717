export type BmsUrl = string;

export function isRooted(url: BmsUrl): boolean {
  return url.startsWith('http://') || url.startsWith('https://');
}

export function joinUrl(base: BmsUrl, extension: BmsUrl): BmsUrl {
  if (isRooted(extension)) {
    throw Error(`Unable to join '${base}' and '${extension}', as the extension url is absolute`);
  }

  const canonicalBase = base.endsWith('/') ? base.substring(0, base.length - 1) : base;
  const canonicalExtension = extension.startsWith('/') ? extension.substring(1) : extension;

  return `${canonicalBase}/${canonicalExtension}`;
}

export function toAbsoluteUrl(url: BmsUrl): BmsUrl {
  if (isRooted(url)) {
    return url;
  }
  return joinUrl(window.location.origin, url);
}

/**
 * Appends the {@param query} to the given {@param baseUrl} by combining both queries.
 * @param baseUrl The base url which might already contain a query string.
 * @param query The query string to be appended.
 */
export function appendQuery(baseUrl: String, query: String) {
  const extensionQuery = query.startsWith('?') ? query.substring(1) : query;

  const queryIndex = baseUrl.indexOf('?');
  if (queryIndex == -1) {
    return `${baseUrl}?${extensionQuery}`;
  } else {
    const baseWithoutQuery = baseUrl.substring(0, queryIndex);
    const baseQuery = baseUrl.substring(queryIndex + 1);
    return `${baseWithoutQuery}?${baseQuery}&${extensionQuery}`;
  }
}
