import { Collapsible } from '@aos/styleguide-react';
import { useState } from 'react';
import { Ticket } from '@/models/operation/TicketModel';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import useGetRelations from '../../react-query/TicketRelationApi';
import CreateTicketRelationMenu from './create-ticket-relation-menu/CreateTicketRelationMenu';
import TicketRelationTable from './ticket-relation-table/TicketRelationTable';
import Pagination from '../../ui/pagination/Pagination';
import './TicketRelations.scss';
import { useTranslationText } from '@/translation/TranslationHooks';

interface TicketRelationsProps {
  ticket: Ticket;
  onRelationChange: () => void;
  readOnly?: boolean;
}

function TicketRelations({ ticket, onRelationChange, readOnly = false }: TicketRelationsProps) {
  const { t } = useTranslationText('tickets');
  const [currentPage, setCurrentPage] = useState(0);

  const {
    data: relations,
    isLoading,
    refetch: refetchRelations,
  } = useGetRelations(ticket.id.toString(), {
    size: '6',
    page: currentPage.toString(),
  });

  function handleRelationChange() {
    onRelationChange();
    void refetchRelations();
  }

  return (
    <Collapsible
      header={t('ticketDependencies')}
      badgeCount={relations?.totalElements ?? 0}
      isInitiallyCollapsed={false}
    >
      {!readOnly && (
        <>
          <CreateTicketRelationMenu currentTicketId={ticket.id} onSuccess={handleRelationChange} readOnly={readOnly} />
          <hr className="ticket-relation-divider" />
        </>
      )}
      <LoadingSpinner isLoading={isLoading}>
        {relations && (
          <>
            <TicketRelationTable
              ticketId={ticket.id}
              ticketRelations={relations.content}
              onDeleteRelation={handleRelationChange}
              readOnly={readOnly}
            />
            <div className="mt-16">
              <Pagination
                currentPage={relations.pageable.pageNumber}
                totalPages={relations.totalPages}
                handleOnPageChange={setCurrentPage}
                size="is-small"
              />
            </div>
          </>
        )}
      </LoadingSpinner>
    </Collapsible>
  );
}

export default TicketRelations;
