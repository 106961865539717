import { Controls, Field, Label, Table as AosTable } from '@aos/styleguide-react';
import './AllTasks.scss';
import { NumberParam, StringParam, withDefault } from 'use-query-params';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import Pagination from '../../ui/pagination/Pagination';
import { pathForAllTasksWithFilter, useGetAllTasks } from '@/react-query/TicketingSystemApi';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/models/pagination/Pagination';
import { TaskQuery, taskQueryParamConfigMap, TaskSort } from '@/models/tasks/TaskQuery';
import UserRoleCheck from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import NotAuthorized from '../../auth/not-authorized-page/NotAuthorized';
import { useTranslationText } from '@/translation/TranslationHooks';
import useToggle from '../../custom-hooks/useToggle';
import AllTaskFilter from './filter/AllTaskFilter';
import TableHeader, { TableColumn } from '../../ui/table-header/TableHeader';
import { TaskTableItem } from './item/TaskTableItem';
import { getNextSortState } from '@/ui/table-sort/TableSort';
import AosActionBar from '../../ui/action-bar/AosActionBar';
import { DebouncedAosSearchBar } from '@/ui/filter/search-bar/AosSearchBar';
import AosExportButton from '../export-button/AosExportButton';
import AosFilterMenuButton from '../../ui/filter/AosFilterMenuButton';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';
import useQueryParamsWithPageReset from '../../custom-hooks/useQueryParamsWithPageReset';
import { addSelectedTenantsToPath } from '@/react-query/CustomFetch';
import TableHintWrapper, { calculateTableState } from '@/ui/table-hint-wrapper/TableHintWrapper';
import { hasFilter } from '@/generic-components/filter-dropdown/FilterDropdown';

export default function AllTasks() {
  const { t } = useTranslationText('tickets');
  const { t: tCommons } = useTranslationText('commons');

  const [query, setQuery] = useQueryParamsWithPageReset({
    ...taskQueryParamConfigMap,
    page: withDefault(NumberParam, DEFAULT_PAGE),
    size: withDefault(NumberParam, DEFAULT_PAGE_SIZE),
    sort: withDefault(StringParam, TaskSort.IdDesc),
  });
  const [isFilterHidden, toggleIsFilterHidden] = useToggle(false);

  const { currentTenant } = useCurrentTenant();

  const selectedTenants = [currentTenant];

  const { data: tasksPaged, isLoading, isError } = useGetAllTasks({}, query as TaskQuery);

  const tableColumns: TableColumn[] = [
    { property: 'id', translationKey: 'idLabel' },
    { property: 'state', translationKey: 'stateLabel' },
    { property: 'ticketSnapshot.title', translationKey: 'titleLabel' },
    { property: 'contractId', translationKey: 'contractor', disableSort: true },
    { property: 'createdAt', translationKey: 'createdAtLabel' },
    { property: 'isFavorite', translationKey: 'favorite' },
  ];

  function onSortChange(property: string, currentSortState: string | undefined) {
    const resetSortState = !query.sort.startsWith(property);
    const sortState = getNextSortState(currentSortState, resetSortState);
    setQuery({ ...query, sort: `${property}:${sortState}` });
  }

  function onPageChange(page: number) {
    setQuery({ ...query, page });
  }

  const handleFilterChange = (name: keyof TaskQuery, value: string | string[] | undefined) => {
    setQuery((prev) => ({ ...prev, [name]: value, page: DEFAULT_PAGE }));
  };

  const resetTaskFilter = () => {
    setQuery({
      hasOpenQuestions: undefined,
      contractIds: undefined,
      states: undefined,
      startedBefore: undefined,
      startedAfter: undefined,
      onlyFavorites: undefined,
      title: undefined,
      contractors: undefined,
    });
  };

  return (
    <div className="all-tasks has-pagination">
      <UserRoleCheck
        requiredPermission={UserResourcePermissions.Task.Read}
        renderNoPermission={() => <NotAuthorized />}
      >
        <AosActionBar
          left={
            <DebouncedAosSearchBar
              value={query.query ?? ''}
              isLoading={isLoading}
              onChangeDebounced={(newValue) => handleFilterChange('query', newValue)}
              placeholder={t('taskSearchPlaceholder')}
              size="is-small"
              label={tCommons('search')}
            />
          }
          right={
            <Field>
              <Label size="is-small">{tCommons('moreActions')}</Label>
              <Controls>
                <AosExportButton
                  href={addSelectedTenantsToPath(pathForAllTasksWithFilter(query as TaskQuery), selectedTenants)}
                  data-role="export-tasks-button"
                  size="is-small"
                />
                <AosFilterMenuButton toggleMenu={toggleIsFilterHidden} />
              </Controls>
            </Field>
          }
        />
        <div className="all-tasks-overview" data-role="all-tasks-overview">
          <div className="is-fullwidth is-full-height">
            <LoadingSpinner isLoading={isLoading}>
              <div className="table-container is-full-height">
                <TableHintWrapper
                  table={
                    <>
                      <AosTable>
                        <TableHeader
                          columns={tableColumns}
                          onSortChange={onSortChange}
                          currentSort={query.sort}
                          translationSection="tickets"
                        />
                        {!!tasksPaged?.content?.length && (
                          <AosTable.Body>
                            {tasksPaged?.content.map((task, index) => (
                              <TaskTableItem task={task} index={index} key={`task-item-${task.id}`} />
                            ))}
                          </AosTable.Body>
                        )}
                      </AosTable>
                      {tasksPaged && tasksPaged.content.length > 0 && (
                        <footer className="pagination-footer">
                          <Pagination
                            pageRangeDisplayed={query.page ?? 0}
                            currentPage={query.page ?? 0}
                            totalPages={tasksPaged?.totalPages ?? 0}
                            handleOnPageChange={onPageChange}
                            size="is-small"
                          />
                        </footer>
                      )}
                    </>
                  }
                  tableResultOption={calculateTableState(tasksPaged?.content ?? [], isError, hasFilter(query))}
                />
              </div>
            </LoadingSpinner>
          </div>
          <AllTaskFilter
            taskFilter={query}
            handleFilterChange={handleFilterChange}
            resetTaskFilter={resetTaskFilter}
            isFilterHidden={isFilterHidden}
            hideFilter={toggleIsFilterHidden}
          />
        </div>
      </UserRoleCheck>
    </div>
  );
}
