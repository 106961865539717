import { ReactElement, useRef, useState } from 'react';
import { faEllipsisH, faPencil, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useQueryClient } from '@tanstack/react-query';
import { Control, Field, Label, TextInput as AosTextInput } from '@aos/styleguide-react';
import { MutationKey, MutationPath, useDeleteMutation } from '@/react-query/MutationQueries';
import {
  TicketingApiPrefix,
  TicketMediaUrlBuilders,
  useGetSeverities,
  useGetStates,
  useGetTasksByTicketId,
  useGetTicket,
  useGetTypes,
  useGetUser,
} from '@/react-query/TicketingSystemApi';
import { useTranslationText } from '@/translation/TranslationHooks';
import ActionBar from '../../ui/action-bar/ActionBar';
import { Dropdown } from '@/ui/dropdown/Dropdown';
import TicketTabs from '../tabs/TicketTabs';
import {
  getSeverityValues,
  getTypeValues,
  mapSeverityToOption,
  mapStateToOption,
  mapTypeToOption,
  mapUsersToSimpleOptions,
} from '@/models/operation/TicketFunctions';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import { invalidateAllTickets, invalidateTicketDetails } from '@/react-query/InvalidationQueries';
import UserRoleCheck, { useAuthUser } from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import Modal from '../../ui/modal/Modal';
import useErrorsCollector from '../../custom-hooks/UseErrorsCollector';
import { userToDisplayNameWithMail } from '@/models/operation/UserModel';
import useGetAllUsers from '../../react-query/getUsers';
import TicketArticles from '../articles/TicketArticles';
import './TicketDetails.scss';
import Media from '../../ui/media/Media';
import { useGetComponent, useGetDescendentComponentStats } from '@/react-query/ComponentApi';
import { TicketContracts } from '../contract/TicketContracts';
import { useLongParamId } from '@/custom-hooks/UseParamId';
import ComponentStatusTag from '../../components/component-status-tag/ComponentStatusTag';
import { ComponentDescendentComponents } from '@/components/component-detail-items/ComponentDescendentComponents';
import NavigationRoutes from '../../routing/NavigationRoutes';
import FavoriteInput from './favorite-input/FavoriteInput';
import { TicketAlerts } from '../alerts/TicketAlerts';
import PrintButton from '../../ui/print-button/PrintButton';
import { TaskState } from '@/models/operation/TaskDto';
import { formatDateAndTime } from '@/utils/dateFormatting';
import DetailInfoField from '../../ui/detail-info-field/DetailInfoField';
import { allowImagesAndPdf } from '@/models/media/Media';
import TicketRelations from '../ticket-relations/TicketRelations';
import AutogeneratedTicketInformation from '../ticket-autogenerated/AutogeneratedTicketInformation';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import ActorTextInput from '../../ui/ActorTextInput';
import MultiSelectDropdown from '../../ui/multi-select-dropdown/MultiSelectDropdown';
import RichTextContent from '../../ui/rich-text-content/RichTextContent';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';

export default function TicketDetails() {
  const { id: ticketId, ErrorPage } = useLongParamId();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTaskHintModalVisible, setIsTaskHintModalVisible] = useState(false);
  const [lastHistoryUpdate, setLastHistoryUpdate] = useState(Date.now());
  // Utils
  const { t } = useTranslationText('tickets');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tCommon } = useTranslationText('commons');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hasPermission } = useAuthUser();

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteTicket, {
    onSuccess: async () => {
      await invalidateAllTickets(queryClient).then(() => navigate(-1));
      toast.success(tSuccess('deleteTicket'));
    },
    onError: () => {
      toast.error(`${tError('error')}: ${tError('ticketDeleteError')}`);
    },
  });

  // CurrentTicket
  const {
    data: currentTicket,
    isLoading: isTicketLoading,
    error: getTicketFetchError,
  } = useGetTicket(ticketId, {
    refetchOnWindowFocus: false,
  });

  const { data: ticketComponent, isError: componentUnknown } = useGetComponent(currentTicket?.assignedComponent?.id!, {
    enabled: !!currentTicket?.assignedComponent,
    suppressErrorToast: true,
  });

  const { data: descendentComponentStats, isLoading: isDescendentComponentStatsLoading } =
    useGetDescendentComponentStats(ticketComponent?.id || '', 'part-of', {
      enabled: !!ticketComponent && !componentUnknown,
    });

  const shouldShowStatusForDescendentComponents =
    descendentComponentStats &&
    descendentComponentStats.healthy + descendentComponentStats.disrupted + descendentComponentStats.failed > 0;

  const { data: severities, isLoading: isSeverityLoading, error: getSeverityFetchError } = useGetSeverities();
  const { data: states, isLoading: isStatesLoading, error: getStatesFetchError } = useGetStates();
  const { data: types, isLoading: isTypesLoading } = useGetTypes();
  const { data: users, isLoading: isAllUsersLoading } = useGetAllUsers();
  const { data: user, isLoading: isUserLoading } = useGetUser(currentTicket?.assigneeId!, {
    enabled: !!currentTicket?.assigneeId,
  });

  const onDelete = () => deleteMutate({ path: `${TicketingApiPrefix}/ticket/${ticketId}` });

  const { data: taskData, isLoading: areTasksLoading } = useGetTasksByTicketId(ticketId, { enabled: !!ticketId });
  const unfinishedTaskLength = taskData?.filter((task) => task.state !== TaskState.DONE)?.length ?? 0;

  const errors = useErrorsCollector([
    {
      fetchError: getTicketFetchError,
      errorText: <Trans i18nKey="errorTexts.404_ticket.text" values={{ id: ticketId }} />,
    },
    {
      fetchError: getSeverityFetchError,
      errorText: '404_severity',
    },
    {
      fetchError: getStatesFetchError,
      errorText: '404_ticketsStates',
    },
  ]);

  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    contentRef: printRef,
    bodyClass: 'ticket-print',
    documentTitle: `E21X-BMS-${ticketId}-${currentTicket?.title ?? 'Unbekanntes Ticket'}`,
  });

  const getItems = () => {
    const renderItems: ReactElement[] = [];

    if (hasPermission(UserResourcePermissions.Ticket.Delete)) {
      renderItems.push(
        <button
          className="dropdown-item"
          onClick={() => (unfinishedTaskLength ? setIsTaskHintModalVisible(true) : setIsModalVisible(true))}
          data-role="ticket-delete-button"
          key="ticket-delete-button"
          type="button"
        >
          {t('ticketDelete')}
        </button>,
      );
    }

    return renderItems;
  };

  const ticketTypeValues = getTypeValues(currentTicket?.type);
  const ticketSeverityValues = getSeverityValues(currentTicket?.severity);

  return (
    <LoadingSpinner isLoading={isTicketLoading} errors={[...errors, ErrorPage]}>
      <div className="flex-container">
        <ActionBar
          right={
            <>
              <PrintButton onClick={() => handlePrint()} data-role="ticket-print-button" />
              <UserRoleCheck requiredPermission={UserResourcePermissions.Ticket.Update}>
                <Link to="edit">
                  <button
                    className="button button-actionbar is-primary text-icon-button"
                    type="button"
                    aria-label="edit-button"
                    data-role="edit-ticket-button"
                  >
                    <span>
                      {tCommon('edit')}
                      <FontAwesomeIcon icon={faPencil} />
                    </span>
                  </button>
                </Link>
              </UserRoleCheck>
              <Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />
            </>
          }
        />

        <div ref={printRef} className="columns no-overflow-content" data-role="ticket-details">
          <div className="ticket-details column is-7">
            {currentTicket && (
              <AutogeneratedTicketInformation actorKind={currentTicket.author.kind} showInformationText />
            )}

            <div className="wrapper">
              <div className="severities">
                <FormFieldWrapper
                  label={t('fieldSeverity')}
                  controlIconLeft={ticketSeverityValues.icon}
                  isLoading={isSeverityLoading}
                  controlClassnames={ticketSeverityValues.className}
                >
                  <AosTextInput
                    value={
                      mapSeverityToOption(
                        t,
                        severities?.find((severity) => severity.key === currentTicket?.severity),
                      )?.label
                    }
                    readOnly
                  />
                </FormFieldWrapper>
              </div>
              <DetailInfoField label={t('number')} value={ticketId} />
              <Field className="is-fullwidth">
                <Label isRequired>{t('titleLabel')}</Label>
                <Control>
                  <AosTextInput value={currentTicket?.title} readOnly />
                </Control>
              </Field>
            </div>

            <div className="component-wrapper">
              <div className="component-and-link-wrapper">
                <Field className="is-fullwidth">
                  <Label>{t('assignedComponent')}</Label>
                  <Control leftIcon={ticketComponent?.isFavorite && 'star'}>
                    <AosTextInput
                      value={ticketComponent?.displayName ?? ''}
                      placeholder={!ticketComponent ? 'Keine Komponente ausgewählt' : ''}
                      readOnly
                    />
                  </Control>
                </Field>
                {ticketComponent && (
                  <>
                    <Link
                      to={NavigationRoutes.ComponentId(ticketComponent.id)}
                      className="without-label-wrapper"
                      title={t('linkToComponentTooltip', { componentName: ticketComponent.displayName })}
                    >
                      <FontAwesomeIcon icon={faUpRightFromSquare} />
                    </Link>

                    <div className="without-label-wrapper">
                      <ComponentStatusTag status={ticketComponent.status} />
                    </div>
                  </>
                )}
              </div>
              {ticketComponent && (
                <div className="descendant-component-status-wrapper">
                  {shouldShowStatusForDescendentComponents && (
                    <ComponentDescendentComponents
                      label={t('statusDescendentComponents')}
                      isLoading={isDescendentComponentStatsLoading}
                      descendentComponentStats={descendentComponentStats}
                    />
                  )}
                </div>
              )}
            </div>
            {componentUnknown && <div>{t('unknownAssignedComponent')}</div>}
            <FormFieldWrapper label={t('descriptionLabel')}>
              <RichTextContent value={currentTicket?.description ?? ''} dataRole="ticket-description" readOnly />
            </FormFieldWrapper>
            <Media
              deletionPath={MutationPath.DeleteTicketMedia}
              mutationPath={MutationPath.UploadTicketMedia(ticketId.toString())}
              mutationKey={MutationKey.PostUploadTicketMedia}
              media={currentTicket?.media ?? []}
              urlBuilders={TicketMediaUrlBuilders}
              label={t('attachments')}
              isEditable
              isSideView
              supportedFileTypes={allowImagesAndPdf}
              onMediaChange={() => {
                void invalidateTicketDetails(queryClient, ticketId);
              }}
            />
            <div className="tabs-wrapper">
              <TicketTabs
                ticketId={ticketId}
                severities={severities ?? []}
                states={states ?? []}
                lastHistoryUpdate={lastHistoryUpdate}
              />
            </div>
          </div>
          <div className="ticket-details column is-5">
            <FormFieldWrapper label={t('fieldState')} isLoading={isStatesLoading}>
              <AosTextInput
                value={
                  mapStateToOption(
                    t,
                    states?.find((state) => state.key === currentTicket?.state),
                  )?.label
                }
                readOnly
              />
            </FormFieldWrapper>
            <FormFieldWrapper
              label={t('fieldType')}
              controlIconLeft={ticketTypeValues.icon}
              isLoading={isTypesLoading}
              controlClassnames={ticketTypeValues.className}
            >
              <AosTextInput
                value={
                  mapTypeToOption(
                    t,
                    types?.find((type) => type.key === currentTicket?.type),
                  )?.label
                }
                readOnly
              />
            </FormFieldWrapper>

            {currentTicket && <ActorTextInput actor={currentTicket.author} label={t('fieldAuthor')} readOnly />}

            <Field>
              <Label isRequired>{t('assignedUser')}</Label>
              <Control isLoading={isAllUsersLoading || isUserLoading}>
                <AosTextInput
                  value={(user && userToDisplayNameWithMail(user)) ?? ''}
                  placeholder={t('noAssignedUser')}
                  readOnly
                />
              </Control>
            </Field>
            <FormFieldWrapper label={t('assignedObservers')}>
              <MultiSelectDropdown
                isLoading={isAllUsersLoading || isUserLoading}
                mappedValues={mapUsersToSimpleOptions(
                  users?.content.filter((option) => currentTicket?.observerIds?.includes(option.id)),
                )}
                options={[]}
                onChange={() => undefined}
                placeholder={t('noObserversAssigned')}
                isDropdownIndicatorEnabled={false}
                isSearchable={false}
              />
            </FormFieldWrapper>

            {currentTicket && (
              <TicketRelations
                ticket={currentTicket}
                onRelationChange={() => setLastHistoryUpdate(Date.now())}
                readOnly
              />
            )}

            {currentTicket && (
              <div className="form-row">
                <div className="is-flex-grow">
                  <UserRoleCheck
                    requiredPermission={UserResourcePermissions.TicketFavorite.Create.and(
                      UserResourcePermissions.TicketFavorite.Delete,
                    )}
                  >
                    <FavoriteInput ticket={currentTicket} label={t('favoriteTicket')} />
                  </UserRoleCheck>
                </div>
                <div className="is-flex-grow">
                  <UserRoleCheck requiredPermission={UserResourcePermissions.TicketReportableProcess.Read}>
                    <FormFieldWrapper label={t('reportableProcess')}>
                      <SwitchInput
                        id={`ticket-reportable-process-${ticketId}`}
                        checked={currentTicket.reportableProcess}
                        secondLabel={currentTicket.reportableProcess ? 'Ja' : 'Nein'}
                        disabled
                      />
                    </FormFieldWrapper>
                  </UserRoleCheck>
                </div>
              </div>
            )}

            <div className="form-row">
              <DetailInfoField
                className="is-flex-grow"
                label={t('createdAtLabel')}
                value={currentTicket ? formatDateAndTime(currentTicket.createdAt) : '-'}
              />
              <DetailInfoField
                className="is-flex-grow"
                label={t('modifiedAtLabel')}
                value={currentTicket ? formatDateAndTime(currentTicket.lastModified) : '-'}
              />
            </div>
            <div className="ticket-details">
              <TicketContracts component={ticketComponent} taskData={taskData} areTasksLoading={areTasksLoading} />
              <TicketArticles ticketComponent={ticketComponent} />
              <UserRoleCheck requiredPermission={UserResourcePermissions.TicketAlerts.Read}>
                <TicketAlerts ticketId={ticketId} />
              </UserRoleCheck>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isVisible={isModalVisible}
        title={t('ticketDelete')}
        cancelBtnText={tCommon('abort')}
        confirmBtnText={tCommon('delete')}
        onCancel={() => setIsModalVisible(false)}
        onConfirm={onDelete}
        onClose={() => setIsModalVisible(false)}
      >
        <p>{t('warningTicketDelete')}</p>
      </Modal>
      <Modal
        isVisible={isTaskHintModalVisible}
        title={t('ticketCantBeDeleted')}
        confirmBtnText={tCommon('ok')}
        onClose={() => setIsTaskHintModalVisible(false)}
        onConfirm={() => setIsTaskHintModalVisible(false)}
        hideCancelButton
      >
        {t('ticketHasOpenTaskHints')}
      </Modal>
    </LoadingSpinner>
  );
}
