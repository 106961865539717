import { useCallback, useEffect, useState } from 'react';
import { useTranslationText } from '@/translation/TranslationHooks';
import { GenericActionConfigurationProps } from './GenericActionConfigurationProps';
import { MailTemplate, RuleAction, RuleTrigger, UsersMailTemplate } from '@/models/operation/AutomationRuleModel';
import { SwitchInput } from '@/ui/switch/SwitchInput';
import './SendEmailMessageAction.scss';
import TextArea from '../../../ui/text-area/TextArea';
import FormFieldWrapper from '../../../ui/form-field-wrapper/FormFieldWrapper';
import { useGetMailTemplate } from '@/react-query/AutomationApi';
import { MutationKey, MutationPath, usePostMutation } from '@/react-query/MutationQueries';

export interface SendCustomMailConfiguration extends RuleAction {
  type: 'sendCustomMailMessage';
  templateName: string;
  body: string;
}

interface SendEmailMessageActionProps extends GenericActionConfigurationProps<SendCustomMailConfiguration> {
  selectedTrigger: RuleTrigger;
}

function delayExecution<T extends (...args: any[]) => any>(func: T, delay: number): T {
  let timeoutId: NodeJS.Timeout;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): ReturnType<T> {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
    return undefined as ReturnType<T>;
  } as T;
}

export default function SendCustomMailMessageAction({
  configuration,
  handleConfigurationUpdate,
  selectedTrigger,
}: SendEmailMessageActionProps) {
  const { t } = useTranslationText('automations');
  const [actionConfiguration, setActionConfiguration] = useState<SendCustomMailConfiguration | undefined>(
    configuration,
  );
  const [customMailMessage, setCustomMailMessage] = useState(configuration?.body ?? '');

  const { mutate: requestServersideRendering } = usePostMutation<UsersMailTemplate, MailTemplate>(
    MutationKey.MailTemplateServersideRendering,
    {
      onSuccess: (renderedUserTemplate) => {
        const newActionConfiguration: SendCustomMailConfiguration = {
          type: 'sendCustomMailMessage',
          templateName: selectedTrigger.type,
          body: renderedUserTemplate.body,
        };

        setActionConfiguration(newActionConfiguration);
        handleConfigurationUpdate(newActionConfiguration);
      },
      onError: () => {
        setActionConfiguration({
          type: 'sendCustomMailMessage',
          templateName: selectedTrigger.type,
          body: t('invalidTemplateError'),
        });
      },
    },
  );

  const renderOnServerDelayed = useCallback(delayExecution(requestServersideRendering, 3000), []);

  const { data: mailTemplate } = useGetMailTemplate(selectedTrigger.type, {
    enabled: !!selectedTrigger.type,
  });

  useEffect(() => {
    setActionConfiguration((prevState) => ({
      type: 'sendCustomMailMessage',
      templateName: selectedTrigger.type,
      body: prevState?.body ?? '',
    }));
  }, [selectedTrigger]);

  return (
    <div className="send-email-message">
      <FormFieldWrapper label={t('sendToMeMyselfAndI_label')}>
        <SwitchInput id="sendToMyself" checked disabled />
      </FormFieldWrapper>
      <FormFieldWrapper label={t('sendMailSubject_label')}>
        <div className="color-unset" dangerouslySetInnerHTML={{ __html: mailTemplate?.subject ?? '' }} />
      </FormFieldWrapper>
      <FormFieldWrapper label={t('sendMailBody_label')}>
        <TextArea
          value={customMailMessage}
          onValueChange={(newTemplateText: string) => {
            setCustomMailMessage(newTemplateText);
            renderOnServerDelayed({
              body: { type: selectedTrigger.type, content: newTemplateText },
              path: MutationPath.RenderMailTemplateServerside,
            });
          }}
        />
      </FormFieldWrapper>
      <div dangerouslySetInnerHTML={{ __html: actionConfiguration?.body ?? '' }} />
    </div>
  );
}
