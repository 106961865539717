import { useCallback } from 'react';
import { ActionType, RuleAction, RuleTrigger } from '@/models/operation/AutomationRuleModel';
import ChangeTicketAssigneeAction from './actions/ChangeTicketAssigneeAction';
import SendCustomMailMessageAction from './actions/SendCustomMailMessageAction';
import ChangeTicketSeverityAction from './actions/ChangeTicketSeverityAction';
import ChangeTicketStateAction from './actions/ChangeTicketStateAction';
import CollapsibleCheck from '../../ui/collapsible/CollapsibleCheck';
import { useOptionalTranslationText, useTranslationText } from '@/translation/TranslationHooks';
import SendTemplateMailMessageAction from '@/automation/action/actions/SendTemplateMailMessageAction';

export interface SelectActionsProps {
  action?: RuleAction;
  actionType: ActionType;
  handleChange: (action: RuleAction) => void;
  onUncheck: (actionConfiguration?: RuleAction) => void;
  selectedTrigger: RuleTrigger;
}

interface ActionConfigurationProps {
  actionType: ActionType;
  configuration?: any;
  handleConfigurationUpdate: (config: any) => void;
  selectedTrigger: RuleTrigger;
}

function ActionConfiguration({
  handleConfigurationUpdate,
  configuration,
  actionType,
  selectedTrigger,
}: ActionConfigurationProps) {
  switch (actionType) {
    case 'changeTicketAssignee':
      return (
        <ChangeTicketAssigneeAction
          configuration={configuration}
          handleConfigurationUpdate={handleConfigurationUpdate}
        />
      );
    case 'changeTicketSeverity':
      return (
        <ChangeTicketSeverityAction
          configuration={configuration}
          handleConfigurationUpdate={handleConfigurationUpdate}
        />
      );
    case 'changeTicketState':
      return (
        <ChangeTicketStateAction configuration={configuration} handleConfigurationUpdate={handleConfigurationUpdate} />
      );
    case 'sendCustomMailMessage':
      return (
        <SendCustomMailMessageAction
          configuration={configuration}
          handleConfigurationUpdate={handleConfigurationUpdate}
          selectedTrigger={selectedTrigger}
        />
      );
    case 'sendTemplateMailMessage':
      return (
        <SendTemplateMailMessageAction
          handleConfigurationUpdate={handleConfigurationUpdate}
          selectedTrigger={selectedTrigger}
        />
      );
    default:
      return <></>;
  }
}

export default function RuleActionItem({
  action,
  handleChange,
  onUncheck,
  actionType,
  selectedTrigger,
}: SelectActionsProps) {
  const { t } = useTranslationText('automations');
  const { t: optionalTranslationText } = useOptionalTranslationText('automations');
  const description = optionalTranslationText(`${actionType}_description`);

  const handleConfigurationUpdate = useCallback((facts: RuleAction) => handleChange(facts), [handleChange]);

  function conditionallySelectTemplateMailAction(actionType: ActionType) {
    if (actionType === 'sendTemplateMailMessage') {
      handleConfigurationUpdate({
        type: 'sendTemplateMailMessage',
        templateName: selectedTrigger.type,
      });
    }
  }

  return (
    <div key={actionType} className="condition-item control mt-2" data-actiontype={actionType}>
      <CollapsibleCheck
        isChecked={!!action}
        title={t(`${actionType}_title`)}
        onToggle={(isChecked) => (isChecked ? conditionallySelectTemplateMailAction(actionType) : onUncheck(action))}
      >
        {description && <div>{description}</div>}
        <ActionConfiguration
          actionType={actionType}
          configuration={action}
          handleConfigurationUpdate={handleConfigurationUpdate}
          selectedTrigger={selectedTrigger}
        />
      </CollapsibleCheck>
    </div>
  );
}
