import { Link } from 'react-router-dom';
import { RefObject, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionBar from '../../ui/action-bar/ActionBar';
import PrintButton from '../../ui/print-button/PrintButton';
import UserRoleCheck, { useAuthUser } from '../../auth/UserRoleCheck';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import NavigationRoutes from '../../routing/NavigationRoutes';
import { ComponentItem } from '../../models/operation/ComponentModel';
import { CreateTicketRouteState } from '../../ticketing/create-ticket/CreateTicket';
import { Dropdown } from '../../ui/dropdown/Dropdown';
import { useTranslationText } from '../../translation/TranslationHooks';

interface ComponentDetailsActionBarProps {
  printRef: RefObject<HTMLDivElement>;
  component: ComponentItem;
  onComponentDelete: () => void;
  right: any;
}

export default function ComponentDetailsActionBar({
  printRef,
  component,
  right,
  onComponentDelete,
}: ComponentDetailsActionBarProps) {
  const { hasPermission } = useAuthUser();
  const { t } = useTranslationText('components');

  const handlePrint = useReactToPrint({
    contentRef: printRef,
    bodyClass: 'component-print',
    documentTitle: `E21X-BMS-${component.displayName}`,
  });

  const createTicketRouteState = useMemo<CreateTicketRouteState | undefined>(() => {
    if (component === undefined) return undefined;

    return {
      component: {
        id: component.id,
        displayName: component.displayName,
        displayNameAlt1: component.displayNameAlt1,
        permanentId: component.permanentId,
        isFavorite: component.isFavorite,
        componentType: component.componentTypeId,
      },
    };
  }, [component]);

  const getActionMenuItems = () => {
    const renderItems: JSX.Element[] = [];

    if (hasPermission(UserResourcePermissions.Component.Delete)) {
      renderItems.push(
        <button className="dropdown-item" onClick={onComponentDelete} type="button">
          {t('deleteComponent')}
        </button>,
      );
    }

    return renderItems;
  };

  return (
    <ActionBar
      right={
        <>
          <PrintButton onClick={() => handlePrint()} data-role="component-print-button" />
          <UserRoleCheck requiredPermission={UserResourcePermissions.Ticket.Create}>
            <Link
              className="button is-primary"
              to={`${NavigationRoutes.TicketsCurrent}/create`}
              state={createTicketRouteState}
            >
              {right}
            </Link>
          </UserRoleCheck>
          <Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getActionMenuItems} />
        </>
      }
    />
  );
}
