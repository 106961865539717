import { createRef } from 'react';
import { useTranslationText } from '@/translation/TranslationHooks';
import FilterResetButton from '../../ui/filter/FilterResetButton';
import CollapsableSideCard from '../../ui/collapsable-side-card/CollapsableSideCard';
import ComponentStatusFilter from '../../ui/filter/component/ComponentStatusFilter';
import { ComponentFilter } from '@/models/operation/ComponentQuery';
import ComponentContractorsFilter from '../../ui/filter/component/ComponentContractorsFilter';
import ComponentWithoutContractFilter from '../../ui/filter/component/ComponentWithoutContractFilter';
import './AllComponentFilter.scss';
import { useAuthUser } from '@/auth/UserRoleCheck';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import ComponentTypeFilter from '../../ui/filter/component/ComponentTypeFilter';
import ComponentIPAddressFilter from '../../ui/filter/component/ComponentIPAddressFilter';
import ComponentOnlyFavoritesFilter from '../../ui/filter/component/ComponentOnlyFavoritesFilter';
import { useGetComponentFilter } from '@/react-query/ComponentApi';
import { MutationKey, MutationPath } from '@/react-query/MutationQueries';
import FilterDropdown from '../../generic-components/filter-dropdown/FilterDropdown';
import CreateFilterModal from '../../generic-components/CreateFilterModal';

interface AllComponentFilterProps {
  componentFilter: ComponentFilter;
  handleFilterChange: (name: keyof ComponentFilter, value: string | string[] | boolean | undefined) => void;
  applySelectedFilter: (filter: ComponentFilter) => void;
  isFilterHidden: boolean;
  hideFilter: () => void;
  resetComponentFilter: () => void;
  handleUnselectFilter: () => void;
  hasFilter: boolean;
}

export default function AllComponentFilter({
  componentFilter,
  handleFilterChange,
  applySelectedFilter,
  resetComponentFilter,
  isFilterHidden,
  hideFilter,
  handleUnselectFilter,
  hasFilter,
}: AllComponentFilterProps) {
  const { hasPermission } = useAuthUser();
  const { t } = useTranslationText('components');
  const { t: tCommons } = useTranslationText('commons');
  const filterDropdownRef = createRef<any>();

  function parseQueryToFilter(name: string, query: ComponentFilter): ComponentFilter {
    return {
      name,
      componentTypeIds: (query.componentTypeIds as string[])?.length ? query.componentTypeIds : undefined,
      status: (query.status as string[])?.length ? query.status : undefined,
      ipAddress: query.ipAddress ? query.ipAddress : undefined,
      contractors: (query.contractors as string[])?.length ? query.contractors : undefined,
      withoutContract: !!query.withoutContract,
      onlyFavorites: !!query.onlyFavorites,
    };
  }

  function handleTypeOnChange(componentTypeIds: string[]) {
    handleFilterChange('componentTypeIds', componentTypeIds);
  }

  function handleStatusOnChange(newStatus: string[]) {
    handleFilterChange('status', newStatus);
  }

  function handleContractorsOnChange(contractorIds: string[]) {
    if (contractorIds.length > 0) {
      handleFilterChange('withoutContract', undefined);
    }

    handleFilterChange('contractors', contractorIds);
  }

  function handleWithoutContractOnChange(checked: boolean) {
    if (checked) {
      handleFilterChange('contractors', undefined);
    }

    handleFilterChange('withoutContract', checked || undefined);
  }

  function handleIPAddressOnChange(ipAddress: string) {
    const newIpAddress = ipAddress === '' ? undefined : ipAddress;
    handleFilterChange('ipAddress', newIpAddress);
  }

  function handleOnlyFavoritesOnChange(onlyFavorites: boolean) {
    handleFilterChange('onlyFavorites', onlyFavorites || undefined);
  }

  function handleResetFilter() {
    resetComponentFilter();
    filterDropdownRef.current?.resetSelectedFilter();
  }

  const filterHeader = () => (
    <>
      <div className="filter-title">{t('moreFilters')}</div>
      {hasFilter && <FilterResetButton handleReset={handleResetFilter} />}
    </>
  );

  const hasContractAndContractorPermission =
    hasPermission(UserResourcePermissions.Contract.Read) && hasPermission(UserResourcePermissions.Contractor.Read);

  const filterElements = (
    <>
      <ComponentTypeFilter
        label={t('componentType')}
        selectedComponentTypeIds={componentFilter?.componentTypeIds}
        onChange={handleTypeOnChange}
      />
      <ComponentStatusFilter
        label={t('status')}
        selectedStatus={componentFilter?.status}
        onChange={handleStatusOnChange}
      />
      <ComponentIPAddressFilter
        label={t('ipAddress')}
        componentFilter={componentFilter}
        onFilterChange={handleIPAddressOnChange}
      />
      {hasContractAndContractorPermission && (
        <div className="contract-filter">
          <ComponentContractorsFilter
            onChange={handleContractorsOnChange}
            selectedContractors={componentFilter?.contractors}
          />

          <div className="is-divider" data-content={tCommons('or')} />
          <div className="toggle-container">
            <ComponentWithoutContractFilter
              isChecked={componentFilter?.withoutContract || false}
              onChange={handleWithoutContractOnChange}
            />
          </div>
        </div>
      )}
      <div className="toggle-container">
        <ComponentOnlyFavoritesFilter
          isChecked={componentFilter?.onlyFavorites || false}
          onChange={handleOnlyFavoritesOnChange}
        />
      </div>
    </>
  );

  return (
    <div className="filter-menu">
      <CollapsableSideCard isHidden={isFilterHidden} header={filterHeader()} onHideToggle={() => hideFilter()}>
        <div className="all-component-filter">
          <FilterDropdown
            ref={filterDropdownRef}
            query={componentFilter}
            useGetAllFilter={useGetComponentFilter}
            applySelectedFilter={applySelectedFilter}
            resetFilterQuery={resetComponentFilter}
            handleUnselectFilter={handleUnselectFilter}
          />
          {filterElements}
          <CreateFilterModal
            query={componentFilter}
            hasFilter={hasFilter}
            applySelectedFilter={applySelectedFilter}
            createOrUpdateFilterMutationKey={MutationKey.CreateOrUpdateComponentFilter}
            filterForm={filterElements}
            parseQueryToFilter={parseQueryToFilter}
            putFilterMutationPath={MutationPath.PutComponentFilter()}
            useGetAllFilter={useGetComponentFilter}
          />
        </div>
      </CollapsableSideCard>
    </div>
  );
}
