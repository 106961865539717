import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { Collapsible } from '@aos/styleguide-react';
import { useTranslationText } from '@/translation/TranslationHooks';
import useYupLocal from '../../translation/YupLocal';
import { contractValidationSchema } from '@/models/contract-management/ContractValidationSchema';
import { createContractorValidationSchema } from '@/models/contract-management/ContractorValidationSchema';
import { Contract, CreateContractModel } from '@/models/contract-management/ContractManagementModel';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import { useGetAllContractors, useGetContractRoles } from '@/react-query/ContractManagementSystemApi';
import { MutationKey, MutationPath, usePostMutation } from '@/react-query/MutationQueries';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import ActionBar from '../../ui/action-bar/ActionBar';
import CreateContractForm from './CreateContractForm/CreateContractForm';
import CreateOrSelectContractorForm from './CreateOrSelectContractorForm/CreateOrSelectContractorForm';
import { Contractor, CreateContractorModel } from '@/models/contract-management/ContractorModel';
import './CreateContract.scss';
import { CreateSupportContactPersonFormCollapsible } from './CreateSupportContactPersonForm/CreateSupportContactPersonFormCollapsible';
import { ContractRoles } from '@/models/contract-management/ContractRole';

export default function CreateContract() {
  const { t } = useTranslationText('contractManagements');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();
  const navigate = useNavigate();

  const {
    data: contractorsPaged,
    isLoading: isLoadingContractors,
    isError: isErrorContractors,
  } = useGetAllContractors();

  const {
    data: contractRoles,
    isLoading: isLoadingContractRoles,
    isError: isErrorContractRoles,
  } = useGetContractRoles();

  const [selectedContractor, setSelectedContractor] = useState<Contractor | undefined>();

  const contractFormReturn = useForm<CreateContractModel>({
    mode: 'onChange',
    resolver: yupResolver(contractValidationSchema(yup, t)),
    defaultValues: {
      serviceTimes: [{ publicHolidaysIncluded: false }],
      supportContactPersons: [{ role: ContractRoles.faultReport, notifyForNewTask: true }],
    },
  });

  const contractorFormReturn = useForm<CreateContractorModel>({
    mode: 'onChange',
    resolver: yupResolver(createContractorValidationSchema(yup, t)),
  });

  const [preventNavigation, setPreventNavigation] = useState(true);
  usePreventNavigation(
    (contractFormReturn.formState.isDirty || contractorFormReturn.formState.isDirty) && preventNavigation,
    tCommon('discardOpenChangesQuestion'),
  );

  const { mutate: postContractor } = usePostMutation<CreateContractorModel, Contractor>(MutationKey.PostContractor, {
    onError: () => {
      toast.error(tError('createContractorError'));
    },
  });

  const { mutate: postContract, isPending: isPostingContract } = usePostMutation<CreateContractModel, Contract>(
    MutationKey.PostContract,
    {
      onSuccess: () => {
        setPreventNavigation(false);
        toast.success(tSuccess('createContract'));
        setTimeout(() => {
          navigate(-1);
        });
      },
      onError: () => {
        toast.error(tError('createContractError'));
      },
    },
  );

  const postContractForContractor = (contract: CreateContractModel, contractorId: string) => {
    postContract({
      body: {
        ...contract,
        contractorId,
      },
      path: MutationPath.CreateContract,
    });
  };

  function handleSubmitContract(contract: CreateContractModel) {
    // if user has selected an existing contractor, use its ID, otherwise create new contractor and create contract if creation was successful
    if (selectedContractor) {
      postContractForContractor(contract, selectedContractor.id);
    } else {
      postContractor(
        {
          body: contractorFormReturn.getValues(),
          path: MutationPath.CreateContractor,
        },
        {
          onSuccess: (contractor) => {
            postContractForContractor(contract, contractor.id);
          },
        },
      );
    }
  }

  async function validateForms(e: any) {
    e.preventDefault();
    const contractorValidationResult = await contractorFormReturn.trigger();
    const contractValidationResult = await contractFormReturn.trigger();

    if (contractorValidationResult && contractValidationResult) {
      await contractFormReturn.handleSubmit(handleSubmitContract)();
    }
  }

  const isDirty = contractFormReturn.formState.isDirty || contractorFormReturn.formState.isDirty;

  return (
    <LoadingSpinner isLoading={isLoadingContractors} errors={isErrorContractors}>
      <form className="create-contract flex-container" onSubmit={validateForms}>
        <ActionBar
          right={
            <button
              className={classNames('button is-primary', { 'is-loading': isPostingContract })}
              type="submit"
              aria-label="create-button"
              data-role="create-contract-submit"
              disabled={!isDirty}
            >
              <span>{t('saveContract')}</span>
            </button>
          }
        />
        <div className="columns is-align-items-flex-start no-overflow-content">
          <div className="column is-7">
            <CreateContractForm form={contractFormReturn} />
          </div>
          <div className="column is-5">
            <Collapsible isInitiallyCollapsed={false} header={t('contractor')}>
              <CreateOrSelectContractorForm
                contractors={contractorsPaged?.content}
                form={contractorFormReturn}
                onSelectedContractorChange={setSelectedContractor}
                selectedContractor={selectedContractor}
              />
            </Collapsible>
            <LoadingSpinner isLoading={isLoadingContractRoles} errors={isErrorContractRoles}>
              <CreateSupportContactPersonFormCollapsible form={contractFormReturn} contractRoles={contractRoles} />
            </LoadingSpinner>
          </div>
        </div>
      </form>
    </LoadingSpinner>
  );
}
