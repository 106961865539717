import useRuntimeConfig from '../runtime-config/RuntimeConfig';
import IFrameNavigator from '../dashboard/IFrameNavigator';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';
import { useState } from 'react';
import { appendQuery } from '@/utils/BmsUrl';

function useComponentDashboardName() {
  const { currentTenant } = useCurrentTenant();
  return `Component-Reports-Fallback?var-tenant=${currentTenant}`;
}

function useComponentDashboardUrl() {
  const [runtimeConfig] = useRuntimeConfig();
  const dashboardName = useComponentDashboardName();
  const [state] = useState(Math.random());

  return runtimeConfig
    ? appendQuery(`${runtimeConfig?.dashboardUrl}/${dashboardName}`, `theme=light&kiosk&state=${state}`)
    : null;
}

export default function Reports() {
  const dashboardUrl = useComponentDashboardUrl();

  return dashboardUrl ? (
    <iframe title="reports" className="dashboard-frame" src={dashboardUrl} onLoad={IFrameNavigator} />
  ) : (
    <></>
  );
}
