import { NavItem, NavItems as AosNavItems, SubNavItem, SubNavItems } from '@aos/styleguide-react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useLocation } from 'react-router';
import { Link, type To } from 'react-router-dom';
import { MainNavigationItem } from '@/models/navigation/MainNavigationItem';
import { useTranslationText } from '@/translation/TranslationHooks';
import UserRoleCheck from '../../../auth/UserRoleCheck';
import { Environment, useGetChangelog } from '@/react-query/GetChangelogApi';

interface NavItemProps {
  navItems: Record<string, MainNavigationItem>;
}

export default function NavItems({ navItems }: NavItemProps) {
  const { t } = useTranslationText('navigations');
  const { pathname } = useLocation();
  const { data: { environment } = { environment: 'default' } } = useGetChangelog();

  function checkActive(path: string) {
    const hasQueryParams = path.indexOf('?') !== -1;
    return pathname.startsWith(hasQueryParams ? path.substring(0, path.indexOf('?')) : path);
  }

  function childPathIfExists(navItem: MainNavigationItem) {
    return Object.values(navItem?.children || {})?.length ? Object.values(navItem.children!)[0].path : navItem?.path;
  }

  return (
    <AosNavItems>
      {Object.keys(navItems).map((key) => (
        <UserRoleCheck key={navItems[key]?.titleTranslationKey} requiredPermission={navItems[key]?.requiredPermission}>
          <NavItem
            renderAs={Link}
            to={childPathIfExists(navItems[key]) as To}
            isActive={checkActive(navItems[key]?.path)}
            icon={(navItems[key]?.icon as IconDefinition).iconName}
            title={t(`${navItems[key]?.titleTranslationKey}`)}
          >
            {!!navItems[key]?.children && (
              <SubNavItems
                items={Object.keys(navItems[key].children as object).map((cKey) => (
                  <UserRoleCheck
                    key={navItems[key].children![cKey]?.titleTranslationKey}
                    requiredPermission={navItems[key].children![cKey]?.requiredPermission}
                  >
                    <SubNavItem
                      renderAs={Link}
                      to={navItems[key].children![cKey]?.path as To}
                      isActive={checkActive(navItems[key].children![cKey]?.path)}
                      title={t(`${navItems[key].children![cKey]?.titleTranslationKey}`)}
                      isDisabled={
                        environment === Environment.Demo &&
                        navItems[key].children![cKey]?.titleTranslationKey == 'componentMap'
                      }
                    />
                  </UserRoleCheck>
                ))}
              />
            )}
          </NavItem>
        </UserRoleCheck>
      ))}
    </AosNavItems>
  );
}
