import { useEffect, useState } from 'react';

interface StyleWorkaroundProps {
  applyCssWorkarounds?: Boolean;
}

export function StyleWorkarounds({ applyCssWorkarounds }: StyleWorkaroundProps) {
  const [applyWorkarounds, setApplyWorkarounds] = useState<boolean>(applyCssWorkarounds === true);

  const location = window.location.href;

  useEffect(() => {
    if (location.indexOf('#fix') != -1) {
      setApplyWorkarounds(true);
    }
  }, [location]);

  return applyCssWorkarounds || applyWorkarounds ? (
    <link rel="stylesheet" type="text/css" href="/workaround.css" />
  ) : (
    <></>
  );
}
