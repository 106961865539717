import { useRef } from 'react';
import classNames from 'classnames';
import Select, {
  ActionMeta,
  createFilter,
  GroupBase,
  InputActionMeta,
  SelectInstance,
  SingleValue,
} from 'react-select';
import './SingleSelectDropdown.scss';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { useAuthUser } from '@/auth/UserRoleCheck';
import { UserPermission } from '@/auth/UserPermission';
import { CustomMenuList, selectClassNames, selectStyles } from '../select/SelectUtils';
import { formatOptionLabelReact } from '../multi-dropdowns-utils/MultiDropdownUtils';

export interface ReactSelectOption<T> {
  readonly label: string;
  readonly value: T;
}

interface SingleSelectDropdownProps<T> {
  isError?: boolean;
  isLoading?: boolean;
  options: ReactSelectOption<T>[];
  onChange: (selectedOption: SingleValue<ReactSelectOption<T>>, actionMeta: ActionMeta<ReactSelectOption<T>>) => void;
  placeholder?: string;
  value?: SingleValue<ReactSelectOption<T>>;
  tabIndex?: number;
  autoFocus?: boolean;
  formatOptionLabel?: any;
  requiredPermission: UserPermission;
  disabled?: boolean;
  isRequired?: boolean;
  isClearable?: boolean;
  size?: BulmaSize;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  onInputChange?: (value: string, action?: InputActionMeta) => void;
  isLoadingMessage?: () => string | undefined;
  noOptionsMessage?: () => string | undefined;
  filterOption?: (option: FilterOptionOption<ReactSelectOption<any>>, inputValue: string) => boolean;
  isAlternative?: boolean;
  defaultValue?: any;
}

export default function SingleSelectDropdown<T>({
  isError,
  isLoading = false,
  options,
  onChange,
  placeholder,
  value,
  tabIndex,
  autoFocus,
  requiredPermission,
  formatOptionLabel,
  disabled,
  isRequired,
  isClearable,
  size = 'is-normal',
  isSearchable = false,
  openMenuOnClick = true,
  onInputChange,
  isLoadingMessage,
  noOptionsMessage,
  filterOption,
  isAlternative = false,
  defaultValue = undefined,
}: SingleSelectDropdownProps<T>) {
  const { hasPermission } = useAuthUser();

  const selectInputRef = useRef<SelectInstance<ReactSelectOption<T>>>(null);

  const isDisabled = disabled || (requiredPermission && !hasPermission(requiredPermission));

  const onClear = () => {
    selectInputRef.current?.clearValue();
  };

  function handleChange(newValue: SingleValue<ReactSelectOption<T>>, actionMeta: ActionMeta<ReactSelectOption<T>>) {
    if (newValue?.value === value?.value && !isRequired) {
      onClear();
      return;
    }
    onChange(newValue, actionMeta);
  }

  return (
    <Select
      ref={selectInputRef}
      className={classNames({
        'is-error': isError,
      })}
      classNamePrefix="react-select"
      options={options}
      isDisabled={isDisabled}
      isLoading={isLoading}
      onChange={handleChange as any} // Cast ist notwendig, sonst Fehler
      placeholder={placeholder}
      inputId="select-input"
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      value={value}
      formatOptionLabel={formatOptionLabel || formatOptionLabelReact}
      filterOption={filterOption ?? createFilter({ stringify: (option) => option.label })}
      isClearable={isClearable}
      styles={selectStyles}
      classNames={selectClassNames<ReactSelectOption<T>, GroupBase<ReactSelectOption<T>>>(size, isAlternative)}
      isMulti={false}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isSearchable={isSearchable}
      openMenuOnClick={openMenuOnClick}
      onInputChange={onInputChange}
      required={isRequired}
      noOptionsMessage={noOptionsMessage}
      loadingMessage={isLoadingMessage}
      defaultValue={defaultValue}
      components={{
        MenuList: CustomMenuList,
      }}
    />
  );
}
