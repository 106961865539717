import classNames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useGetActionTypes } from '@/react-query/AutomationApi';
import { useTranslationText } from '@/translation/TranslationHooks';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import RuleActionItem from './RuleActionItem';
import RuleActionItemAdded from './RuleActionItemAdded';
import { RuleAction, RuleTrigger } from '@/models/operation/AutomationRuleModel';
import FormFieldWrapper from '@/ui/form-field-wrapper/FormFieldWrapper';

interface RuleActionsOverviewProps {
  actions?: RuleAction[];
  showOnlySummary?: boolean;
  validate?: boolean;
  onValidation: (isValid: boolean) => void;
  onActionConfigured: (condition: RuleAction) => void;
  onUncheckAction: (actionConfiguration?: RuleAction) => void;
  selectedTrigger?: RuleTrigger;
}

export default function RuleActionsOverview({
  actions,
  showOnlySummary,
  onActionConfigured,
  onUncheckAction,
  onValidation,
  validate,
  selectedTrigger,
}: RuleActionsOverviewProps) {
  const { data: availableActions, isLoading: isLoadingActions, isError } = useGetActionTypes();
  const { t } = useTranslationText('automations');

  const isValid = useCallback(() => actions !== undefined && actions.length > 0, [actions]);

  useEffect(() => {
    onValidation(isValid());
  }, [isValid, onValidation]);

  return (
    <LoadingSpinner isLoading={isLoadingActions} errors={isError && 'Rules load failed'}>
      <div className="rule-wrapper">
        <FormFieldWrapper isRequired label={t('selectActionTitle')} />
        {!showOnlySummary && (
          <div
            className={classNames('mb-5', 'mt-1', {
              isInvalid: validate && !isValid(),
            })}
          >
            <p className="mb-5">{t('selectActionDescription')}</p>
            {!selectedTrigger && <p>{t('missingTriggerError')}</p>}
          </div>
        )}
        <div className={classNames({ summaryGrid: showOnlySummary })}>
          {selectedTrigger &&
            availableActions
              ?.sort((a, b) => a.localeCompare(b))
              .map((actionType) => {
                const action = (actions ?? []).find((a) => a.type === actionType);
                if (showOnlySummary) {
                  return action && <RuleActionItemAdded key={actionType} value={t(`${actionType}_title`)} />;
                }
                return (
                  <div key={actionType} onClick={(e) => e.stopPropagation()}>
                    <RuleActionItem
                      actionType={actionType}
                      action={action}
                      handleChange={onActionConfigured}
                      onUncheck={onUncheckAction}
                      selectedTrigger={selectedTrigger}
                    />
                  </div>
                );
              })}
        </div>
      </div>
    </LoadingSpinner>
  );
}
