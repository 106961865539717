import { Hint } from '@aos/styleguide-react';
import { useTranslationText } from '@/translation/TranslationHooks';
import { TableResultOption } from '@/models/wrapped-table/WrappedTableTypes';
import { tableHintConfig } from '@/ui/table-hint-wrapper/TableHintWrapper';

interface HintWrapperProps {
  tableResultOption: TableResultOption;
  gap?: string;
}

function HintWrapper({ tableResultOption, gap = '32' }: HintWrapperProps) {
  const { t } = useTranslationText('commons');
  const config = tableHintConfig[tableResultOption];

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: `${gap}px`,
        alignItems: 'center',
      }}
    >
      <div style={{ width: '440px', height: '77px' }}>
        <Hint style={{ width: '100%' }} color={config.color} icon={config.icon.iconName} title={t(config.titleTextKey)}>
          <span>{t(config.bodyTextKey)}</span>
        </Hint>
      </div>
    </div>
  );
}

export default HintWrapper;
