import { RuleTrigger, TriggerType } from '@/models/operation/AutomationRuleModel';
import CollapsibleCheck from '../../ui/collapsible/CollapsibleCheck';
import { useOptionalTranslationText, useTranslationText } from '@/translation/TranslationHooks';
import TicketCreatedTrigger from './triggers/TicketCreatedTrigger';
import TicketModifiedTrigger from './triggers/TicketModifiedTrigger';
import TicketUnchangedTrigger from './triggers/TicketUnchangedTrigger';

export interface SelectTriggerProps {
  triggerType: TriggerType;
  trigger?: RuleTrigger;
  handleChange: (trigger: RuleTrigger) => void;
  onUncheck: (trigger?: RuleTrigger) => void;
}

interface TriggerConfigurationProps {
  triggerType: TriggerType;
  trigger?: any;
  handleTriggerUpdate: (trigger: RuleTrigger) => void;
  onClear: () => void;
}

interface HandleToggleProps {
  triggerType: TriggerType;
  trigger?: any;
  isChecked: boolean;
  handleChange: (trigger: RuleTrigger) => void;
  onUncheck: (trigger?: RuleTrigger) => void;
}

function TriggerConfiguration({ triggerType, trigger, handleTriggerUpdate, onClear }: TriggerConfigurationProps) {
  switch (triggerType) {
    case 'ticketCreated':
      return (
        <TicketCreatedTrigger trigger={trigger} handleConfigurationUpdate={handleTriggerUpdate} onClear={onClear} />
      );
    case 'ticketModified':
      return (
        <TicketModifiedTrigger trigger={trigger} handleConfigurationUpdate={handleTriggerUpdate} onClear={onClear} />
      );
    case 'ticketUnchanged':
      return (
        <TicketUnchangedTrigger trigger={trigger} handleConfigurationUpdate={handleTriggerUpdate} onClear={onClear} />
      );
    default:
      return <></>;
  }
}

function handleToggle({ triggerType, trigger, isChecked, handleChange, onUncheck }: HandleToggleProps) {
  switch (triggerType) {
    case 'ticketCreated':
    case 'ticketModified':
    case 'ticketUnchanged':
      return !isChecked && onUncheck(trigger);
    default:
      return isChecked ? handleChange({ type: triggerType }) : onUncheck(trigger);
  }
}

export default function RuleTriggerItem({ trigger, triggerType, handleChange, onUncheck }: SelectTriggerProps) {
  const { t } = useTranslationText('automations');
  const { t: optionalTranslationText } = useOptionalTranslationText('automations');
  const description = optionalTranslationText(`${triggerType}_description`);

  return (
    <div className="condition-item control mt-2">
      <CollapsibleCheck
        isChecked={!!trigger}
        title={t(`${triggerType}_title`)}
        dataRole={triggerType}
        onToggle={(isChecked) => handleToggle({ triggerType, trigger, isChecked, handleChange, onUncheck })}
      >
        {description && <div>{description}</div>}
        <TriggerConfiguration
          triggerType={triggerType}
          trigger={trigger}
          handleTriggerUpdate={handleChange}
          onClear={() => onUncheck(trigger)}
        />
      </CollapsibleCheck>
    </div>
  );
}
