import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import ActionBar from '../../ui/action-bar/ActionBar';
import { CreateSystemModel, SystemModel } from '@/models/operation/SystemModel';
import { useTranslationText } from '@/translation/TranslationHooks';
import useYupLocal from '../../translation/YupLocal';
import systemValidationSchema from '../SystemValidationSchema';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import { MutationKey, MutationPath, usePostMutation } from '@/react-query/MutationQueries';
import { SystemDetails } from '../system-details/SystemDetails';
import { SystemStages } from '../system-stage/SystemStages';

export default function CreateSystem() {
  const [preventNavigation, setPreventNavigation] = useState<boolean>(true);

  const { t } = useTranslationText('systems');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');

  const { t: tCommon } = useTranslationText('commons');
  const { yup } = useYupLocal();

  const systemFormReturn = useForm<CreateSystemModel>({
    mode: 'onChange',
    resolver: yupResolver(systemValidationSchema(yup, tError)),
  });

  const { handleSubmit, formState } = systemFormReturn;

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const navigate = useNavigate();

  const { mutate, isPending } = usePostMutation<CreateSystemModel, SystemModel>(MutationKey.PostSystem, {
    onSuccess: () => {
      navigate(-1);
      toast.success(tSuccess('createSystem'));
    },
    onError: () => {
      toast.error(tError('createSystemError'));
    },
  });

  const onSubmit = (csm: CreateSystemModel) => {
    setPreventNavigation(false);

    mutate({
      body: {
        name: csm.name,
        supportContactPerson: csm.supportContactPerson,
        supportContactAddress: csm.supportContactAddress,
        description: csm.description,
        integrationsscheinNaming: csm.integrationsscheinNaming,
        stages: csm.stages,
      },
      path: MutationPath.CreateSystem,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex-container">
      <ActionBar
        right={
          <button
            className={classNames('button is-primary', { 'is-loading': isPending })}
            type="submit"
            aria-label="create-button"
            disabled={!formState.isValid || !formState.isDirty}
          >
            <span>{t('save')}</span>
          </button>
        }
      />
      <div className="no-overflow-content">
        <SystemDetails form={systemFormReturn} />
        <SystemStages form={systemFormReturn} />
      </div>
    </form>
  );
}
