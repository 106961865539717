import { TicketSeverity } from '@/models/operation/TicketSeveritiesModel';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { useTranslationText } from '@/translation/TranslationHooks';
import { useGetSeverities } from '@/react-query/TicketingSystemApi';
import { mapOptionToTValue } from '@/ui/select/SelectUtils';
import {
  findSeverityByKey,
  mapSeverityToOption,
  severityLoadOptions,
  SeverityOptionLabel,
} from '@/ticketing/severity-selects/SeveritySelectUtils';
import Select from '@/ui/select/Select';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';

interface SingleSeveritySelectProps {
  onChange: (severity: TicketSeverity | null) => void;
  value: string | null;
  label?: string;
  disableLabel?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  size?: BulmaSize;
}

function SingleSeveritySelect({
  onChange,
  value,
  label,
  disableLabel,
  isRequired,
  isDisabled,
  size,
}: SingleSeveritySelectProps) {
  const { t } = useTranslationText('tickets');
  const { t: tCommons } = useTranslationText('commons');
  const { data: severities = [], isLoading } = useGetSeverities();

  return (
    <Select<TicketSeverity, never, never, false>
      value={value ? mapSeverityToOption(findSeverityByKey(value, severities)) : null}
      onChange={(newSelectedSeverity) => onChange(mapOptionToTValue(newSelectedSeverity))}
      loadOptions={() => severityLoadOptions(severities)}
      label={disableLabel ? undefined : label}
      isLoading={isLoading}
      isSearchable={false}
      isRequired={isRequired}
      isDisabled={isDisabled}
      size={size}
      requiredPermission={UserResourcePermissions.Ticket.Read}
      loadingMessage={() => tCommons('severitiesAreLoading')}
      placeholder={t('selectSeverities')}
      formatOptionLabel={(opt) => <SeverityOptionLabel payload={opt.payload} />}
    />
  );
}

export default SingleSeveritySelect;
