import './DetailContract.scss';
import { Trans } from 'react-i18next';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { Button } from '@aos/styleguide-react';
import { useGetContractById } from '@/react-query/ContractManagementSystemApi';
import LoadingSpinner from '../../ui/loading-spinner/LoadingSpinner';
import ActionBar from '../../ui/action-bar/ActionBar';
import { useAuthUser } from '@/auth/UserRoleCheck';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import NavigationRoutes from '../../routing/NavigationRoutes';
import { Dropdown } from '@/ui/dropdown/Dropdown';
import { useTranslationText } from '@/translation/TranslationHooks';
import useToggle from '../../custom-hooks/useToggle';
import Modal from '../../ui/modal/Modal';
import ContractDetails from './ContractDetails';
import useParamId from '../../custom-hooks/UseParamId';
import { MutationKey, MutationPath, useDeleteMutation, usePatchMutation } from '@/react-query/MutationQueries';
import { invalidateAllContracts } from '@/react-query/InvalidationQueries';
import useGetComponentsSummarized from '../../react-query/ComponentApi';
import { Patch, replaceProperty } from '@/models/patch/PatchModel';
import { Contract } from '@/models/contract-management/ContractManagementModel';
import { Contractor } from '@/models/contract-management/ContractorModel';
import ChangeableContractor from './ChangeableContractor';
import EditableSupportContactPersons from './EditableSupportContactPersons/EditableSupportContactPersons';
import {
  CreateSupportContactPersonModel,
  SupportContactPersonModel,
} from '@/models/contract-management/SupportContactPersonModel';

export default function DetailContract() {
  const { id: contractId, ErrorPage } = useParamId();
  const { t } = useTranslationText('contractManagements');
  const { t: tCommon } = useTranslationText('commons');
  const { data: contract, isLoading, isFetching, isError, refetch } = useGetContractById(contractId);
  const { data: assignedComponents } = useGetComponentsSummarized(
    { enabled: !!contract },
    { assignedContractId: contract?.id },
  );
  const isAssignedToComponents = !!assignedComponents && assignedComponents.content.length > 0;

  const [isConfirmationModalVisible, toggleConfirmationModal] = useToggle(false);
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hasPermission } = useAuthUser();

  const { mutate: deleteMutate } = useDeleteMutation(MutationKey.DeleteContract, {
    onSuccess: async () => {
      await invalidateAllContracts(queryClient);
      navigate(NavigationRoutes.AllContracts);
      toast.success(tSuccess('deleteContract'));
    },
    onError: () => {
      toast.error(`${tError('error')}: ${tError('contractDeleteError')}`);
    },
  });

  const { mutate, isPending: isPatching } = usePatchMutation<Patch, Contract>(MutationKey.PatchContract);

  const onChange = async () => {
    const result = await refetch();
    return result.data;
  };

  const onChangeContractor = (contractor: Contractor | undefined) => {
    if (contract !== undefined && contractor !== undefined) {
      mutate(
        { body: [replaceProperty('contractor', contractor)], path: MutationPath.PatchContract(contract.id.toString()) },
        {
          onSuccess: async () => {
            await onChange();
          },
          onError: () => {
            toast.error(<Trans i18nKey="errorTexts.patchError.text" values={{ component: tError('contractor') }} />);
          },
        },
      );
    }
  };

  const handleSubmitSupportContactPersons = (
    supportContactPersons: CreateSupportContactPersonModel[] | undefined,
    saveCompleted?: (savedSupportContractPersons: SupportContactPersonModel[] | undefined) => void,
  ) => {
    if (contract !== undefined) {
      mutate(
        {
          body: [replaceProperty('supportContactPersons', supportContactPersons)],
          path: MutationPath.PatchContract(contract.id.toString()),
        },
        {
          onSuccess: async () => {
            const savedContract = await onChange();
            saveCompleted?.(savedContract?.supportContactPersons);
          },
          onError: () => {
            saveCompleted?.(undefined);
            toast.error(
              <Trans i18nKey="errorTexts.patchError.text" values={{ component: tError('supportContactPersons') }} />,
            );
          },
        },
      );
    }
  };

  const onDelete = () => deleteMutate({ path: MutationPath.DeleteContract(contractId) });

  if (ErrorPage) {
    return ErrorPage;
  }

  const getItems = () => {
    const renderItems: JSX.Element[] = [];

    if (hasPermission(UserResourcePermissions.Contract.Delete)) {
      renderItems.push(
        <button
          className="dropdown-item delete-contract-button"
          type="button"
          data-role="delete-contract-button"
          onClick={() => toggleConfirmationModal()}
          key="delete-contract-button"
        >
          {t('deleteContract')}
        </button>,
      );
    }

    return renderItems;
  };

  return (
    <LoadingSpinner
      isLoading={isLoading}
      errors={isError ? <Trans i18nKey="errorTexts.404_contract.text" values={{ id: contractId }} /> : undefined}
    >
      <div className="flex-container">
        <ActionBar right={<Dropdown title={<FontAwesomeIcon icon={faEllipsisH} />} renderItems={getItems} />} />
        <div className="contract-details">
          <div className="columns is-variable is-5  is-align-items-flex-start" data-role="contract-details">
            <div className="column is-7">
              {contract && <ContractDetails contract={contract} onChange={onChange} isFetching={isFetching} />}
            </div>
            <div className="column is-5">
              <ChangeableContractor
                onChange={(selectedOption) => onChangeContractor(selectedOption?.value)}
                contractor={contract?.contractor}
              />
              {contract && (
                <EditableSupportContactPersons
                  currentContactPersons={contract.supportContactPersons}
                  isSaving={isPatching || isFetching}
                  onSubmit={handleSubmitSupportContactPersons}
                />
              )}
              {contract && (
                <Button renderAs="a" href={NavigationRoutes.ComponentsWithContractId(contract.id)} isConfirm>
                  {t('linkToAssignedComponentsText')}
                </Button>
              )}
            </div>
          </div>
          <Modal
            isVisible={isConfirmationModalVisible}
            title={tCommon('deleteContract')}
            confirmBtnText={tCommon('delete')}
            cancelBtnText={tCommon('back')}
            onCancel={() => toggleConfirmationModal()}
            onClose={() => toggleConfirmationModal()}
            onConfirm={onDelete}
          >
            {isAssignedToComponents && (
              <p className="is-assigned-to-component-warning">{tCommon('warningContractHasComponentsAssigned')}</p>
            )}
            <p>{tCommon('warningContractDelete')}</p>
          </Modal>
        </div>
      </div>
    </LoadingSpinner>
  );
}
