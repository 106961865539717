import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { AxiosError } from 'axios';
import MediaImage from './MediaImage';
import { StrapiFile } from '@/models/strapi/StrapiFile';
import UploadFile from '../upload/UploadFile';
import { MutationKey, MutationPath, useDeleteMutation } from '@/react-query/MutationQueries';
import { useTranslationText } from '@/translation/TranslationHooks';
import './Media.scss';
import { MediaUrlBuilders, SupportedFileTypes } from '@/models/media/Media';
import { FetchError } from '@/react-query/FetchError';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { Label } from '@aos/styleguide-react';

export interface MediaProps {
  media: StrapiFile[];
  deletionPath?: (mediaId: number) => MutationPath;
  urlBuilders: MediaUrlBuilders;
  mutationKey?: MutationKey;
  mutationPath?: MutationPath;
  isEditable?: boolean;
  isSideView?: boolean;
  supportedFileTypes?: SupportedFileTypes;
  onMediaChange?: (media: StrapiFile[]) => void;
  onCopyImage?: (url: string) => void;
  label?: string;
  showCopyToClipboardButton?: boolean;
  size?: BulmaSize;
}

export default function Media({
  deletionPath,
  mutationKey,
  mutationPath,
  isSideView,
  media,
  urlBuilders,
  isEditable,
  onCopyImage,
  supportedFileTypes,
  onMediaChange,
  label,
  showCopyToClipboardButton = true,
  size = 'is-normal',
}: MediaProps) {
  const [mediaList, setMediaList] = useState<StrapiFile[]>(media || []);
  const { t } = useTranslationText('errorTexts');
  const { t: tSuccess } = useTranslationText('successTexts');
  const { t: tError } = useTranslationText('errorTexts');
  const { t: tText } = useTranslationText('tickets');

  const { mutate: deleteMedia } = useDeleteMutation();

  const removeDeletedMedia = (deletedMediaId: number) => {
    const cleanedMediaList = mediaList.filter((file) => file.id !== deletedMediaId);
    setMediaList(cleanedMediaList);
    if (onMediaChange) {
      onMediaChange(cleanedMediaList);
    }
  };

  const onMediaDelete = (mediaId: number) => {
    if (deletionPath) {
      deleteMedia(
        { path: deletionPath(mediaId) },
        {
          onSuccess: () => {
            toast.success(tSuccess('mediaDeleted'));
            removeDeletedMedia(mediaId);
          },
          onError: () => {
            toast.error(tError('mediaNotDeleted'));
          },
        },
      );
    } else {
      removeDeletedMedia(mediaId);
    }
  };

  useMemo(() => media && setMediaList(media), [media]);

  return (
    <div className={classNames('field', 'media', { isSideView })}>
      <div className="gallery-wrapper">
        <div className="label-wrapper">
          {(!!media?.length || mutationPath) && label && (
            <Label htmlFor="media-gallery" size={size}>
              {label}
            </Label>
          )}
          {supportedFileTypes?.descriptionTranslationKey && (
            <span className="sub-label">{tText(supportedFileTypes.descriptionTranslationKey)}</span>
          )}
          {mutationKey && mutationPath && isEditable ? (
            <UploadFile
              mutationKey={mutationKey}
              mutationPath={mutationPath}
              supportedFileTypes={supportedFileTypes}
              onSuccess={(uploadedMedia) => {
                if (onMediaChange) {
                  onMediaChange([...media, ...uploadedMedia]);
                  toast.success(tSuccess('mediaUpload'));
                }
              }}
              onUploadError={(e: FetchError | AxiosError) =>
                toast.error(
                  `${t('uploadFile')}: ${
                    e instanceof AxiosError && e?.response?.status === 400 ? t('invalidFileType') : e.message
                  }`,
                )
              }
            />
          ) : (
            <></>
          )}
        </div>

        {mediaList && mediaList.length > 0 && (
          <div className="media-gallery" id="media-gallery" data-role="media-gallery">
            {mediaList.map((m) => (
              <MediaImage
                key={m.id}
                file={m}
                urlBuilders={urlBuilders}
                onDelete={onMediaDelete}
                onCopyImage={onCopyImage}
                isEditable={isEditable}
                showCopyToClipboardButton={showCopyToClipboardButton}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
