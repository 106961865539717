enum ServerStateKeysEnum {
  Alerts = 'Alerts',
  ComponentsAll = 'components',
  Component = 'component',
  ComponentAncestors = 'componentAncestors',
  ComponentsLocated = 'componentsLocated',
  ComponentType = 'componentType',
  DescendentComponentStats = 'descendentComponentStats',
  ComponentSearch = 'componentSearch',
  ComponentAlerts = 'componentalerts',
  ActiveAlerts = 'activealerts',
  AlertsForComponent = 'alertsforcomponent',
  ComponentRelations = 'componentrelations',
  UserOverviewConfig = 'userOverviewConfig',
  System = 'system',
  SystemDetails = 'systemDetails',
  Status = 'Status',
  RelationTypes = 'relationTypes',
  RoleMappings = 'roleMappings',
  TicketSeverities = 'ticketseverities',
  TicketDetails = 'ticketdetails',
  TicketAll = 'ticketall',
  TicketHistory = 'tickethistory',
  TicketComments = 'ticketcomments',
  TicketSearch = 'ticketsearch',
  TicketStates = 'ticketstates',
  TicketUserAll = 'ticketuserAll',
  TicketUser = 'ticketuser',
  TicketAssociatedAlerts = 'ticketAssociatedAlerts',
  KnowledgeBaseArticleAll = 'knowledgeBaseArticleAll',
  ArticleDetails = 'arcticledetails',
  ArticleSearch = 'arcticlesearch',
  ArticleComments = 'articlecomments',
  KnowledgeBaseArticleTagAll = 'knowledgeBaseArticleTagAll',
  KnowledgeBaseComponentTypeAll = 'knowledgeBaseArticleComponentTypeAll',
  KnowledgeBaseComponentAll = 'knowledgeBaseArticleComponentAll',
  KnowledgeBaseArticlesForTicketWithComponent = 'knowledgeBaseArticlesForTicketWithComponent',
  KnowledgeBaseUser = 'knowledgeBaseUser',
  TagDetails = 'tagdetails',
  LastEditedArticles = 'lastEditedArticles',
  FrequentTags = 'frequentTags',
  AutomationRuleAll = 'automationRuleAll',
  AutomationRule = 'automationRule',
  AutomationRuleActions = 'automationRuleActions',
  AutomationRuleConditions = 'automationRuleConditions',
  AutomationRuleTriggers = 'automationRuleTriggers',
  AdvancedRuleAll = 'advancedRuleAll',
  AdvancedRule = 'advancedRule',
  MailTemplate = 'mailTemplate',
  Contract = 'contract',
  ContractAll = 'contractall',
  ContractSearch = 'contractsearch',
  Contractor = 'contractor',
  ContractorAll = 'contractorall',
  ContractRole = 'contractRole',
  TicketTask = 'ticketTask',
  Tasks = 'tasksall',
  Task = 'task',
  TaskComments = 'taskcomments',
  TaskHistory = 'TaskHistory',
  TaskMedia = 'taskmedia',
  TaskStates = 'taskstates',
  ComponentTypeDetails = 'componentTypeDetails',
  UserSettingsAll = 'userSettingsAll',
  UserSettings = 'userSettings',
  UserInfo = 'userInfo',
  Agent = 'agents',
  ModuleMethod = 'moduleMethod',
  SimilarArticles = 'similarArticles',
  ServiceTimeReaction = 'serviceTimeReaction',
  ServiceTimeRestoration = 'serviceTimeRestoration',
  MaintenanceRequest = 'maintenance',
  TicketRelations = 'ticketrelations',
  TicketTypes = 'tickettypes',
  Changelog = 'changelog',
  Features = 'features',
  TicketFilter = 'ticketfilter',
  ComponentFilter = 'componentfilter',
  ActiveAlertFilter = 'activealertfilter',
  ComponentTypeIcon = 'componenttypeicon',
}

export default ServerStateKeysEnum;
